import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SETTINGS from "./SETTINGS";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId
const projectId = 'b8ee3423c5dd8b25fbc0f0b5e51b10ed'


const testnet = {
  chainId: 80002,
  name: 'Amoy',
  currency: 'POL',
  explorerUrl: 'https://amoy.polygonscan.com/',
  rpcUrl: 'https://polygon-amoy-bor-rpc.publicnode.com'
}

const polygonMainnet = {
  chainId: 137,
  name: 'Polygon',
  currency: 'POL',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/9f1e5354e01044d18b20e08585da8330'
}

const bscMainnet = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};

const arbMainnet = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io/',
  rpcUrl: 'https://arb1.arbitrum.io/rpc/'
};

const optMainnet = {
  chainId: 10,
  name: 'Optimism',
  currency: 'ETH',
  explorerUrl: 'https://optimistic.etherscan.io',
  rpcUrl: 'https://mainnet.optimism.io/'
};

// 3. Create a metadata object
const metadata = {
  name: 'GetonGlobal',
  description: 'GetonGlobal',
  url: 'https://geton.global',

}
const test_metadata = {
  name: 'WaveCoin',
  description: 'WaveCoin',
  url: 'https://test.waveswaps.com',

}

const selectedChains = SETTINGS.IS_TEST ? [testnet] : [polygonMainnet];
const selectedMetadata = SETTINGS.IS_TEST ? test_metadata : metadata;

// Create a Web3Modal instance with conditional configuration
const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({
        metadata: selectedMetadata,
        enableEmail: false,
        enableEIP6963: true,
    }),
    chains: selectedChains,
    projectId,
    features: {
      email: true, // default to true
      socials: ['google', 'x', 'github', 'discord', 'apple', 'facebook', 'farcaster'],
      emailShowWallets: true, // default to true
    },
    allWallets: 'SHOW',
    themeMode: 'dark',
    themeVariables: {
        '--w3m-accent': '#0b4182'
    },
    enableOnramp: true,
    enableAnalytics: true,
});

console.log('Web3Modal Initialized:', web3Modal);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
