// src/components/ponzigetonzi/UserDashboard.js

import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import presaleContractInfo from '../../contracts/PresaleContract.json';
import tokenContractInfo from '../../contracts/MyToken.json';
import { Card, Alert } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';

function UserDashboard() {
  const [userAddress, setUserAddress] = useState('');
  const [userContribution, setUserContribution] = useState('0');
  const [userTokenBalance, setUserTokenBalance] = useState('0');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        // Initialize provider and signer
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setUserAddress(address);

        const presaleContract = new Contract(
          presaleContractInfo.address,
          presaleContractInfo.abi,
          signer
        );

        const tokenContract = new Contract(
          tokenContractInfo.address,
          tokenContractInfo.abi,
          provider
        );

        // Fetch user's contribution
        const contribution = await presaleContract.getContribution(address);
        setUserContribution(formatUnits(contribution, 18));

        // Fetch user's token balance
        const tokenBalance = await tokenContract.balanceOf(address);
        setUserTokenBalance(formatUnits(tokenBalance, 18));
      } catch (error) {
        console.error('Error fetching user data:', error);
        setStatusMessage(`An error occurred: ${error.message}`);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Card className="custom-card text-center">
      <Card.Header>
        <FaUserCircle size={32} /> User Dashboard
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>Your Address:</strong>
          <br />
          {userAddress}
        </Card.Text>
        <Card.Text>
          <strong>Your Contribution:</strong> {userContribution} ETH
        </Card.Text>
        <Card.Text>
          <strong>Your Token Balance:</strong> {userTokenBalance} Tokens
        </Card.Text>
        {statusMessage && <Alert variant="danger">{statusMessage}</Alert>}
      </Card.Body>
    </Card>
  );
}

export default UserDashboard;
