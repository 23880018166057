// src/components/SmallMarquee.js
import React from 'react';


const notifications = [
  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },
  
  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "$TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },
  
  { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/GreedGrinder.png", text: "$GRND", url: "https://dexscreener.com/base/0xeca5079d830f97048ba40137d4a6cd962fc07360" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/ReflexCoin.png", text: "$RFX", url: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/GetOnPonzi.png", text: "$GETON", url: "https://dexscreener.com/base/0x13b5A77B116A557efbc148fA2EAa80d81E1fed5B" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/FomoFurnace.png", text: "$FOMO", url: "https://dexscreener.com/base/0xfeee711b4fbccfbe3ddcf667cb973be93587f7b9" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/ChaosCrumbs.png", text: "$CHC", url: "https://dexscreener.com/base/0xe5a1b8464ad3b453fcee8887f92a134dd33a3749" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/TemptationToken.png", text: "TEMP", url: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/crypto-spotlight" },

];

const SmallMarquee = () => {
  return (
    <div className="marquee-container-small">
      <div className="marquee-content-small">
        {notifications.map((notification, index) => (
          <a
            key={`first-${index}`}
            href={notification.url}
            target="_blank"
            rel="noopener noreferrer"
            className="marquee-link"
          >
            <img src={notification.icon} alt="" className="marquee-icon" />
            {notification.text}
          </a>
        ))}
        {notifications.map((notification, index) => (
          <a
            key={`second-${index}`}
            href={notification.url}
            target="_blank"
            rel="noopener noreferrer"
            className="marquee-link"
          >
            <img src={notification.icon} alt="" className="marquee-icon" />
            {notification.text}
          </a>
        ))}
      </div>
    </div>
  );
};


export default SmallMarquee;
