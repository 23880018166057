import React, { useState, useEffect } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import { BiLogoTelegram, BiLogoWhatsapp, BiLogoFacebookCircle, BiLogoTwitter } from 'react-icons/bi';
import { BsTelegram, BsWhatsapp, BsFacebook, BsTwitterX  } from 'react-icons/bs';
import copyImg from '../copy.png';
import StripeLoaderImg from '../stripe_loader.svg';
import BettingContractABI from '../abis/BettingContractABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers';
import { useNavigate } from 'react-router-dom';

const contractAddress = "0x3E87aEb238adda9a5f682E9cC82124973e38Ad25";
const contractAddressOld = "0xe23eB07b1F145c1515De7542A25FdE752aE998Ad";

const RefferalComponent = ({ provider, isConnected }) => {
    const [loading, setLoading] = useState(true);
    const [copying, setCopying] = useState(false);
    const [refferalCode, setRefferalCode] = useState('');
    const [userCode, setUserCode] = useState('');
    const [activePackage, setActivePackage] = useState(0);




    const shareUrl = `https://geton.global/game?ref=${userCode}`;
    const title = "Play Price Prediction Game, earn rewards, and explore the power of blockchain technology with GetonGlobal!";

    const copyCode = () => {
        setCopying(true);
        navigator.clipboard.writeText(shareUrl);
        setTimeout(() => setCopying(false), 2000);
    };


    const fetchBalance = async () => {
        setLoading(true);
        try {
          const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(contractAddress, BettingContractABI, signer);
          const contractOld = new Contract(contractAddressOld, BettingContractABI, signer);
    
    
          const activePackage = await contract.packageExpiry(signer.address);
          const activePackageOld = await contractOld.packageExpiry(signer.address);
          
          setActivePackage(parseInt(activePackage) + parseInt(activePackageOld));
         
        } catch (error) {
          console.error("Failed to fetch balance:", error);
        } finally {
          setLoading(false);
        }
      };

    const getUserCode = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(contractAddress, BettingContractABI, signer);
        const code = await contract.inviteCodeForAddress(signer.address);
        setUserCode(code);
        setLoading(false);
    };

    const createCode = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            const contract = new Contract(contractAddress, BettingContractABI, signer);
            const refTx = await contract.addRefferal(refferalCode.toUpperCase());
            await refTx.wait();
            const contractOld = new Contract(contractAddressOld, BettingContractABI, signer);
            const refTxOld = await contractOld.addRefferal(refferalCode.toUpperCase());
            await refTxOld.wait();
        } catch (error) {
            console.error("Error creating code:", error);
        } finally {
            getUserCode();
            setTimeout(() => setLoading(false), 2000);
        }
    };

    useEffect(() => {
        if (isConnected) {
            getUserCode();
            fetchBalance();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);

    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} style={{ width: "40%" }} alt="Loading" />
                    </center>
                </div>
            </div>
        );
    }

    return (
        <Accordion defaultActiveKey="1" className='refAccordion'>
            <Accordion.Item eventKey="2" className="my-0">
                <Accordion.Header>Referral</Accordion.Header>
                <Accordion.Body>
                    {activePackage <= 0 ? (
                        <p style={{color:"#FFFFFF"}}>
                            <center>
                            You need to buy a game package to create referral code.
                            </center>
                            </p>
                    ):(<div className="text-center mb-2">
                        {userCode === "" ? (
                            <div className=''>
                                <div className='input-group' style={{ marginBottom: "14px" }}>
                                    <label className='mb-1'>Create referral code</label>
                                    <input
                                        type="text"
                                        value={refferalCode}
                                        onChange={(e) => setRefferalCode(e.target.value)}
                                        placeholder="Referral Code"
                                        className='custom-select'
                                    />
                                </div>
                                <Button className='buton buton-secondary d-lg-block' onClick={createCode}>CREATE</Button>
                            </div>
                        ) : (
                            <>
                            <h6 className="mt-1">Referral Code: {userCode}</h6>
                            <Button variant="primary mt-3" onClick={copyCode} className="buton">
                                <img
                                    src={copyImg}
                                    alt=""
                                    style={{ width: "13px", marginRight: "5px", filter: "", opacity: "1" }}
                                />
                                {copying ? "Copied!" : "Copy Code"}
                            </Button>

                            {/* Share Buttons */}
                            <div className="share-buttons mt-4" style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                {/* Facebook Share Button */}
                                <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-icons-primary"
                                    style={{ padding: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <BsFacebook style={{ fontSize: '1.5rem' }} />
                                </a>

                                {/* Twitter Share Button */}
                                <a
                                    href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-icons-primary"
                                    style={{ padding: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <BsTwitterX style={{ fontSize: '1.5rem' }} />
                                </a>

                                {/* Telegram Share Button */}
                                <a
                                    href={`https://t.me/share/url?url=${shareUrl}&text=${title}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-icons-primary"
                                    style={{ padding: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <BsTelegram style={{ fontSize: '1.5rem' }} />
                                </a>

                                {/* WhatsApp Share Button */}
                                <a
                                    href={`https://api.whatsapp.com/send?text=${title}%20${shareUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-icons-primary"
                                    style={{ padding: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <BsWhatsapp style={{ fontSize: '1.5rem' }} />
                                </a>
                            </div>
                            </>
                        )}
                    </div>
                )}

                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default RefferalComponent;
