import React, { useEffect, useState } from 'react';
import { BiCopy } from "react-icons/bi";
import Table from 'react-bootstrap/Table';
import SETTINGS from '../SETTINGS';  // Ensure the path to SETTINGS is correct

const Tokens = () => {
  const [assetData, setAssetData] = useState({});  // State to store data for assets
  const [loading, setLoading] = useState(true);    // Loading state

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    switch (network) {
      case 'Polygon Mainnet':
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'BNB Smart Chain':
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error('Unsupported network:', network);
        return null;
    }
  };

  const fetchPrice = async (asset, key) => {
    const { tokenAddress1, tokenAddress2, walletAddress, tokenDecimals1, tokenDecimals2, apiKey, network } = asset;
    const apiUrl1 = getApiUrlForNetwork(network, tokenAddress1, walletAddress, apiKey);
    const apiUrl2 = getApiUrlForNetwork(network, tokenAddress2, walletAddress, apiKey);

    if (!apiUrl1 || !apiUrl2) return { price: 'Error' };

    try {
      const [response1, response2] = await Promise.all([fetch(apiUrl1), fetch(apiUrl2)]);
      const data1 = await response1.json();
      const data2 = await response2.json();
      const balance1 = data1.result / Math.pow(10, tokenDecimals1);  
      const balance2 = data2.result / Math.pow(10, tokenDecimals2);  
      const price = (balance2 / balance1).toFixed(2);

      return { price };
    } catch (error) {
      console.error('Error fetching price data for', key, error);
      return { price: 'Error' };
    }
  };

  const fetchRevampAmount = async (asset, key) => {
    const { tokenAddress1, wallet, tokenDecimals1, apiKey, network } = asset;
    const apiUrlRevamp = getApiUrlForNetwork(network, tokenAddress1, wallet, apiKey);

    if (!apiUrlRevamp) return { revampAmount: 0, formattedRevampAmount: '0' };

    try {
      const response = await fetch(apiUrlRevamp);
      const data = await response.json();

      if (data.status !== '1' || !data.result) {
        console.error('Error fetching revamp amount:', data.message || 'Invalid data');
        return { revampAmount: 0, formattedRevampAmount: '0' };
      }

      const revampAmount = data.result / Math.pow(10, tokenDecimals1);
      const formattedRevampAmount = Math.floor(revampAmount).toLocaleString();

      return { revampAmount, formattedRevampAmount };
    } catch (error) {
      console.error('Error fetching revamp amount for', key, error);
      return { revampAmount: 0, formattedRevampAmount: '0' };
    }
  };

  const calculateCirculatingSupply = (totalSupply, revampAmount) => {
    const circulatingSupply = Math.floor(totalSupply - revampAmount);
    return circulatingSupply.toLocaleString();
  };

  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const key of Object.keys(SETTINGS.ASSETS)) {
      const asset = SETTINGS.ASSETS[key];
      const priceData = await fetchPrice(asset, key);
      const revampData = await fetchRevampAmount(asset, key);

      const circulatingSupply = calculateCirculatingSupply(asset.totalSupply, revampData.revampAmount);
      results[key] = { ...priceData, ...revampData, circulatingSupply };

      await delay(1000);  // Delay between API calls to avoid rate limits
    }

    setAssetData(results);
    setLoading(false);
  };

  const handleManualRefresh = () => {
    fetchAllData();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId, networkName, rpcUrl, currencySymbol, blockExplorerUrl) => {
    try {
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        return;
      }

      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x' + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x' + chainId.toString(16),
                  chainName: networkName,
                  rpcUrls: [rpcUrl],
                  nativeCurrency: {
                    name: currencySymbol,  
                    symbol: currencySymbol,  
                    decimals: 18,
                  },
                  blockExplorerUrls: [blockExplorerUrl],
                }],
              });
            } catch (addError) {
              console.error('Error adding network:', addError);
              return;
            }
          } else {
            console.error('Error switching network:', switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(`${tokenSymbol} successfully added to MetaMask on ${networkName}`);
      } else {
        console.log('Token addition to MetaMask failed.');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask:', error);
    }
  };

  const handleAddToken = (asset) => {
    const { 
      tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl 
    } = asset;

    addTokenFunction(tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl);
  };

  return (
    <div className="container tokenstable">
      <h2 className='pageTitle tokens-header'>Tokens</h2>
      <p className="hero-p sub-header">Explore listed tokens with direct blockchain links, easy MetaMask integration, and live price tracking from DEX markets.</p> 

      {/* Button for manual refresh and loading state */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button 
          onClick={handleManualRefresh} 
          style={{ padding: '10px 20px', fontSize: '14px', cursor: 'pointer', marginRight: '10px' }}
          className="refresh-button hero-button "
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Refresh Data'}
        </button>
      </div>

      <div className="table-responsive">
        <Table className="w-100 no-border-bottom" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #dee2e6' }}>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Logo</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Asset Name</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Price ($)</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Total</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Crafting</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Circulating</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Contract</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Wallet</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>DEX</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Chart</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(SETTINGS.ASSETS).map((key) => {
              const asset = SETTINGS.ASSETS[key];
              const assetDataItem = assetData && assetData[key];

              return (
                <tr key={key} style={{ borderBottom: 'none' }}>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <a href={asset.chainExplorer} target="_blank" rel="noopener noreferrer">
                      <img src={asset.assetLogo} alt={asset.logo} width="23" height="auto" />
                    </a>
                  </td>
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <span className="asset-name">{asset.name}</span>&nbsp;
                    <span className="asset-symbol">{asset.symbol}</span>
                  </td>
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {loading ? 'Loading...' : assetDataItem?.price || 'Fetching...'}
                  </td>
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {asset.totalSupply.toLocaleString()}
                  </td>
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {loading ? 'Loading...' : assetDataItem?.formattedRevampAmount || 'Fetching...'}
                  </td>
                  <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {loading ? 'Loading...' : assetDataItem?.circulatingSupply || 'Fetching...'}
                  </td>
                  <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <button onClick={() => copyToClipboard(asset.tokenAddress1)} className="copy-button">
                      <BiCopy className="icon me-1" />
                    </button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <button onClick={() => handleAddToken(asset)} className="add-token-button">
                      <img src="https://tokens.waveswaps.com/images/metamask.svg" alt="MetaMask" />
                    </button>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="https://app.uniswap.org/swap" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                      <img src="https://tokens.waveswaps.com/images/dex/uni.svg" alt="Uniswap" width="20" height="auto" />
                    </a>
                    <a href={asset.chart1}  target="_blank" rel="noopener noreferrer">
                      <img src="https://tokens.waveswaps.com/images/dex/dexscreener.svg" alt="dexScreener" width="20" height="auto" />
                    </a>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <a href={asset.chart} target="_blank" rel="noopener noreferrer">
                      <img src="https://tokens.waveswaps.com/images/cmc.svg" alt="Chart" width="20" height="auto" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tokens;