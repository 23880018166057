// polygon bridge 0x5FCa3cfb3B7E54d833648a44422285229df36560
// bsc bridge 0x1E2A7824942754c30D8a24C1de8B383d417b804d
const SETTINGS = {
  IS_TEST: false,
  version:"1.0.9",
  nodeContract: "0xe8FD75Cd812BFCb395526546714AdC265C9A4b1f",
  collection: "0x062bbfC594b63456E8b199661eD7636F5446c602",
  tokenSaleAddress: "0x093eF8ad83AbC9e22C7c2c19F17b4cb11c837CF2",
  mainContract: "0xd46928C9E6A653A6c1C6Bccfc75eE9De15Ec8Eea",
  usdtAddr: "0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
  tokenAddress: "0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
  globalTokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
  stakingContract: "0xD0620809A7decC0577D501f76f739743A8B80373",
  globalStakingContract: "0x518ae4F86BC83c98B63046875132fc4E2c16ECF1",
  daoToken: "0x8053563b0678a2Cd900BAbc8756cC55e25685215",
  standardDaoContract: "0xDc3d572d3dC0D79a085cf21F9C5a0065d48F83E4",
  weightedDaoContract: "0xF09C8e19d2E8d031a9866F53215Fcc9778D1D70f",
  nativeSymbol: "POL",
  nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
  usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
  gblSymbol: "GBL",
  tokenSymbol: "WAVE",
  collectionSymbol: "WAVEND",
  openSeaLink: "",
  spatialLink: "",
  MAX_SUPPLY: 20000,
  AP_PRICE: 1,
  END_TIME: 1843121631 * 1000,
  NEXT_AIRDROP: 1725174000 * 1000,
  BRIDGE_CONTRACTS: {
    56: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
    137: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
    42161: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
    10: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
  },
  BRIDGE_CONTRACTS_OLD: {
    56: "0x4b3656175fee78AA32E070B296239080fad70D76",
    137: "0x57B8E42a0A74eD2772073c1eDF92e34307F98D30",
    42161: "0x5ca457e16BbD8DA80ee54F362c4Cf6fe5c7f7c0F",
    10: "0x5ca457e16BbD8DA80ee54F362c4Cf6fe5c7f7c0F",
  },
  RPC_URLS: {
    // Binance Smart Chain
    56: "https://bsc-dataseed.binance.org/",

    // Polygon (Mainnet) - using Infura with your project ID
    137: `https://polygon-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,

    // Optimism (Mainnet) - using Infura with your project ID
    10: `https://optimism-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,

    // Arbitrum One (Mainnet) - using Infura with your project ID
    42161: `https://arbitrum-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,
  },

  proposalCategories: ["Development", "Marketing", "Initiatives", "Education"],
  proposalCategoriesWeighted: [
    "Supply Management",
    "Blockchain Expansion",
    "Governance Policies",
    "Revenue Allocation",
  ],
  NODE_INFO: {
    "1": {
      dao_power: 10,
      title: "WAVE",
      sort: "NODE",
      level: "WAVE NODE",
      lifespan: "270",
      dailyRewards: 0.5,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node.png",
      craftElements: [],
      price: 100,
      buyCurrency: "WAVE",
      nodeCurrency: "WAVE",
      hoverText: "WAVE NODE",
      total: 2718,
    },
    "2": {
      dao_power: 20,
      title: "CHRONOS",
      sort: "NODE",
      level: "CHRONOS NODE",
      lifespan: "270",
      dailyRewards: 1,
      refferalPercent: 1,
      imgUrl: "https://node.waveswaps.com/node1.png",
      craftElements: [
        "https://node.waveswaps.com/node.png",
        "https://node.waveswaps.com/node70.png",
      ],
      price: 110,
      buyCurrency: "",
      nodeCurrency: "WAVE",
      hoverText: "CHRONOS NODE",
      total: 2718,
    },
    "3": {
      dao_power: 30,
      title: "STELLAR",
      sort: "NODE",
      level: "STELLAR NODE",
      lifespan: "90",
      dailyRewards: 3,
      refferalPercent: 3,
      imgUrl: "https://node.waveswaps.com/node2.png",
      craftElements: [
        "https://node.waveswaps.com/node1.png",
        "https://node.waveswaps.com/node70.png",
      ],
      price: 120,
      buyCurrency: "",
      nodeCurrency: "WAVE",
      hoverText: "STELLAR NODE",
      total: 8154,
    },
    "4": {
      dao_power: 45,
      title: "ECLIPSE",
      sort: "NODE",
      level: "ECLIPSE NODE",
      lifespan: "60",
      dailyRewards: 4.5,
      refferalPercent: 4.5,
      imgUrl: "https://node.waveswaps.com/node3.png",
      craftElements: [
        "https://node.waveswaps.com/node2.png",
        "https://node.waveswaps.com/node70.png",
      ],
      price: 150,
      buyCurrency: "",
      nodeCurrency: "WAVE",
      hoverText: "ECLIPSE NODE",
      total: 16308,
    },
    "5": {
      dao_power: 60,
      title: "QUANTUM",
      sort: "NODE",
      level: "QUANTUM NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 6,
      imgUrl: "https://node.waveswaps.com/node4.png",
      craftElements: [
        "https://node.waveswaps.com/node3.png",
        "https://node.waveswaps.com/node70.png",
      ],
      price: 190,
      buyCurrency: "",
      nodeCurrency: "WAVE",
      hoverText: "QUANTUM NODE",
      total: 24462,
    },
    "6": {
      dao_power: 90,
      title: "GLOBALIS",
      sort: "NODE",
      level: "GLOBALIS NODE",
      lifespan: "9",
      dailyRewards: 30,
      refferalPercent: 9,
      imgUrl: "https://node.waveswaps.com/node5.png",
      craftElements: [
        "https://node.waveswaps.com/node4.png",
        "https://node.waveswaps.com/node70.png",
      ],
      price: 240,
      buyCurrency: "",
      nodeCurrency: "WAVE",
      hoverText: "GLOBALIS NODE",
      total: 81540,
    },
    LOAN: {
      dao_power: 0,
      title: "LOAN NODE",
      level: "LOAN NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 0,
      imgUrl: "https://wave.waveswaps.com/img/globalis.webp",
      craftElements: [
        "https://wave.waveswaps.com/img/quantum.webp",
        "https://wave.waveswaps.com/img/grosh.webp",
      ],
      price: 270,
      buyCurrency: "WAVE",
      hoverText: "LOAN NODE",
    },
    BTC: {
      dao_power: 0,
      title: "BTC NODE",
      level: "BTC NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 9,
      imgUrl: "https://wave.waveswaps.com/img/btc.webp",
      craftElements: [
        "https://wave.waveswaps.com/img/quantum.webp",
        "https://wave.waveswaps.com/img/grosh.webp",
      ],
      price: 0.000001,
      buyLogo: "https://wave.waveswaps.com/img/assets/btc.svg",
      buyCurrency: "BTC",
      hoverText: "BTC NODE",
    },
    ETH: {
      dao_power: 0,
      title: "ETH NODE",
      level: "ETH NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 9,
      imgUrl: "https://wave.waveswaps.com/img/eth.webp",
      craftElements: [
        "https://wave.waveswaps.com/img/quantum.webp",
        "https://wave.waveswaps.com/img/grosh.webp",
        "https://wave.waveswaps.com/img/pron.webp",
      ],
      price: 0.000123,
      buyLogo: "https://wave.waveswaps.com/img/assets/eth.svg",
      buyCurrency: "ETH",
      hoverText: "ETH NODE",
    },
    XRP: {
      dao_power: 0,
      title: "XRP NODE",
      level: "XRP NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 9,
      imgUrl: "https://wave.waveswaps.com/img/xrp.webp",
      craftElements: [
        "https://wave.waveswaps.com/img/quantum.webp",
        "https://wave.waveswaps.com/img/grosh.webp",
        "https://wave.waveswaps.com/img/pron.webp",
      ],
      price: 100.0001,
      buyLogo: "https://wave.waveswaps.com/img/assets/xrp.svg",
      buyCurrency: "XRP",
      hoverText: "XRP NODE",
    },
    SOL: {
      dao_power: 0,
      title: "SOL NODE",
      level: "SOL NODE",
      lifespan: "30",
      dailyRewards: 9,
      refferalPercent: 9,
      imgUrl: "https://wave.waveswaps.com/img/sol.webp",
      craftElements: [
        "https://wave.waveswaps.com/img/quantum.webp",
        "https://wave.waveswaps.com/img/grosh.webp",
        "https://wave.waveswaps.com/img/pron.webp",
      ],
      price: 10.0001,
      buyLogo: "https://wave.waveswaps.com/img/assets/sol.svg",
      buyCurrency: "SOL",
      hoverText: "SOL NODE",
    },
    "10": {
      dao_power: 0,
      title: "PIPL",
      sort: "POWER CORE",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node10.png",
      craftElements: [],
      price: 10,
      buyCurrency: "PIPL",
      hoverText: "PIPL POWER CORE",
      buyToken: "0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
      logoToken: "https://logos.waveswaps.com/assets/PIPL.svg",
    },
    "20": {
      dao_power: 0,
      title: "GETON",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node20.png",
      craftElements: [],
      price: 0.1,
      buyCurrency: "GETON",
      hoverText: "GETON POWER CORE",
      buyToken: "0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
      logoToken: "https://logos.waveswaps.com/assets/GETON.svg",
    },
    "30": {
      dao_power: 0,
      title: "GROSH",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node30.png",
      craftElements: [],
      price: 10,
      buyCurrency: "GROSH",
      hoverText: "GROSH POWER CORE",
      buyToken: "0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
      logoToken: "https://logos.waveswaps.com/assets/GROSH.svg",
    },
    "40": {
      dao_power: 0,
      title: "CODE",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node40.png",
      craftElements: [],
      price: 10,
      buyCurrency: "CODE",
      hoverText: "CODE POWER CORE",
      buyToken: "0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
      logoToken: "https://logos.waveswaps.com/assets/CODE.svg",
    },

    "50": {
      dao_power: 0,
      title: "META",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node50.png",
      craftElements: [],
      price: 10,
      buyCurrency: "META",
      hoverText: "META POWER CORE",
      buyToken: "0xD51E7065B942D5A5886915C6BEbf42600D546162",
      logoToken: "https://logos.waveswaps.com/assets/META.svg",
    },

    "60": {
      dao_power: 0,
      title: "PRON",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node60.png",
      craftElements: [],
      price: 0.01,
      buyCurrency: "PRON",
      hoverText: "PRON POWER CORE",
      buyToken: "0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
      logoToken: "https://logos.waveswaps.com/assets/PRON.svg",
    },
    "70": {
      dao_power: 0,
      title: "GBL",
      lifespan: "300",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node70.png",
      craftElements: [],
      price: 1,
      buyCurrency: "GBL",
      hoverText: "GBL POWER CORE",
      buyToken: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      logoToken: "https://logos.waveswaps.com/assets/GBL.svg",
    },
    "80": {
      dao_power: 0,
      title: "WAVE",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://node.waveswaps.com/node80.png",
      craftElements: [],
      price: 10,
      buyCurrency: "WAVE",
      hoverText: "WAVE POWER CORE",
      buyToken: "0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
      logoToken: "https://logos.waveswaps.com/assets/WAVE.svg",
    },
    "18": {
      dao_power: 0,
      title: "BTC",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://wave.waveswaps.com/img/btc.webp",
      craftElements: [],
      price: 10,
      buyCurrency: "BTC",
      hoverText: "BTC",
    },
    "19": {
      dao_power: 0,
      title: "ETH",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://wave.waveswaps.com/img/eth.webp",
      craftElements: [],
      price: 10,
      buyCurrency: "ETH",
      hoverText: "ETH",
    },
    "14": {
      dao_power: 0,
      title: "XRP",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://wave.waveswaps.com/img/xrp.webp",
      craftElements: [],
      price: 10,
      buyCurrency: "XRP",
      hoverText: "XRP",
    },
    "21": {
      dao_power: 0,
      title: "SOL",
      dailyRewards: 0,
      refferalPercent: 0,
      imgUrl: "https://wave.waveswaps.com/img/sol.webp",
      craftElements: [],
      price: 10,
      buyCurrency: "SOL",
      hoverText: "SOL",
    },
  },
  AFFILIATE_INFO: {
    SAGEMASTER: {
      logo: "https://wave.waveswaps.com/img/affiliate/sagemaster.webp",
      alias: "SAGEMASTER",
      address: "SageMaster",
      telegram: "link",
      description:
        "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
      referral: "https://uniswap.org/referral_link",
      isVisible: false,
    },
    UNISWAP: {
      logo: "https://wave.waveswaps.com/img/affiliate/uniswap.svg",
      alias: "Uniswap DEX",
      address: "UniSwap",
      telegram: "link",
      description:
        "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
      referral: "https://uniswap.org",
      isVisible: true,
    },
    SUSHISWAP: {
      logo: "https://wave.waveswaps.com/img/affiliate/sushiswap.svg",
      alias: "SushiSwap DEX",
      address: "SushiSwap",
      telegram: "link",
      description:
        "SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi ",
      referral: "https://sushi.com",
      isVisible: true,
    },
    WAWESWAPS: {
      logo: "https://wave.waveswaps.com/img/affiliate/wshare.svg",
      alias: "WAVESWAPS FEE",
      address: "WaveSwaps",
      telegram: "link",
      description: (
        <span>
          WaveSwaps stands at the forefront of decentralized finance (DeFi),
          pioneering a unique and community-driven approach to{" "}
          <a
            href="https://docs.waveswaps.com/corporate/cryptocurrencies/waveswaps-governance"
            target="_blank"
            rel="noopener noreferrer"
          >
            governance
          </a>{" "}
          through its innovative shareholding model with WSHARE tokens. This
          section delves into the intricate workings of WaveSwaps' governance,
          showcasing how the platform is managed and maintained, and explaining
          how you can actively participate in shaping its future.
        </span>
      ),
      referral:
        "https://docs.waveswaps.com/corporate/cryptocurrencies/waveswaps-governance",
      isVisible: true,
    },
    // Add more affilaites as needed...
  },
  TOKEN_ADDRESSES: {
    GBL: {
      1: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
      56: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      137: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      42161: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      10: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
    },
    WAVE: {
      1: "-",
      56: "-",
      137: "0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
      42161: "-",
      10: "-",
    },
    META: {
      1: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
      56: "0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",
      137: "0xD51E7065B942D5A5886915C6BEbf42600D546162",
      42161: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
      10: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
    },
    CODE: {
      1: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
      56: "0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
      137: "0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
      42161: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
      10: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
    },
    PIPL: {
      1: "0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",
      56: "0xE12723848699AdcbFF466050cE17Cbf5C6156720",
      137: "0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
      42161: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
      10: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
    },
    GROSH: {
      1: "0xd92B3100e19bef7A32aef1451D891f99F9316e13",
      56: "0xb67B9a92D902F8beadEe9846CDde40a10F198808",
      137: "0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
      42161: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
      10: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
    },
    GETON: {
      1: "0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
      56: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
      137: "0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
      42161: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
      10: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
    },
    PRON: {
      1: "0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
      56: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
      137: "0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
      42161: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
      10: "0x3692668069fe43771f46EEA1aaa28064debFFA9c",
    },
  },
  PRICES: {
    WAVE: {
      137: 1,
      name: "WAVE",
    },
    GBL: {
      56: 0.002,
      137: 20,
      42161: 50,
      10: 100,
      name: "GBL",
    },
    CODE: {
      56: 0.000012,
      137: 3.2,
      name: "CODE",
    },
    META: {
      56: 0.000007,
      137: 2.9,
      name: "META",
    },
    PIPL: {
      56: 0.00001,
      137: 2.8,
      name: "PIPL",
    },
    GETON: {
      56: 0.00004,
      137: 140,
      name: "GETON",
    },
    GROSH: {
      56: 0.000016,
      137: 2.8,
      name: "GROSH",
    },
    PRON: {
      56: 0.000033,
      137: 1290,
      name: "PRON",
    },
    LOS: {
      56: 0.0005,
      137: 24.0,
      name: "LOS",
    },
    CARB: {
      56: 0.00002,
      137: 18.0,
      name: "CARB",
    },
    CBDX: {
      56: 0.000067,
      137: 8.3,
      name: "CBDX",
    },
    APOD: {
      56: 0.000026,
      137: 1.2,
      name: "APOD",
    },
    eGROSH: {
      56: 0.0018,
      137: 1.2,
      name: "eGROSH",
    },
    GMAAR: {
      56: 0.000001,
      name: "GMAAR",
    },
  },
  ASSETS: {
    WaveCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
    chainExplorer: "https://polygonscan.com/token/0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
    name: "WaveCoin",
    symbol: "WAVE",
    walletAddress: '0x0ef8291c70e6fafdd16e7b648a73bb4fa7af31dd',
    wallet: '0xcFbA2075F6F4bd815aef59b8554e17a20da67698',
    tokenAddress1: '0x3c2d4cfF75434205Ec98980f7B506d035CA7d923',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x0ef8291c70e6fafdd16e7b648a73bb4fa7af31dd",
    chart1: "https://dexscreener.com/polygon/0x25c0f0f0a7ab69883ecc32040b1976cb6fc4ef98",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE',
    price:{
        56:0.01,
        137:1,
        }
  },
  GlobalToken: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/GBL.svg",
    chainExplorer: "https://polygonscan.com/token/0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
    name: "GlobalToken",
    symbol: "GBL",
    walletAddress: '0xe0b2b83718400188528f914b9991ff3246fa009b',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 17900000,
    chart: "https://coinmarketcap.com/currencies/global-token/",
    chart1: "https://dexscreener.com/polygon/0xe0b2b83718400188528f914b9991ff3246fa009b",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'XXE1HV4MS8KMJ69PN38JRIGFUMT8DKBWRY',
    price:{
        56:0.01,
        137:1,
        }
  },
  CryptoCode: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/CODE.svg",
    chainExplorer: "https://polygonscan.com/token/0x2d04d3f49d11c11ad99caca515d209c741c1bd9b",
    name: "CryptoCode",
    symbol: "CODE",
    walletAddress: '0xade8045983eed33d604dbaa56e32a22b63ca9b12',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0x2d04d3f49d11c11ad99caca515d209c741c1bd9b',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 21000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0xade8045983eed33d604dbaa56e32a22b63ca9b12/",
    chart1: "https://dexscreener.com/polygon/0x0d217b8ae66483fd4b29a9930ee17be265636eda",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'IKQ8JR39YAI2ICYBAFD2IUB2AKHR79Y2TR',
    price:{
        56:0.01,
        137:1,
        }
  },
  MetaCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/META.svg",
    chainExplorer: "https://polygonscan.com/token/0xD51E7065B942D5A5886915C6BEbf42600D546162",
    name: "MetaCoin",
    symbol: "META",
    walletAddress: '0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0xd51e7065b942d5a5886915c6bebf42600d546162',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 210000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128/",
    chart1: "https://dexscreener.com/polygon/0x35e6981b70b72358d3142b7dd7f1d9ddce417b8a",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'GYZEQUPJMXVFVX4T2WVR7BQ4AIRZGSJK2T',
    price:{
        56:0.01,
        137:1,
        }
  },
  PiplCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/PIPL.svg",
    chainExplorer: "https://polygonscan.com/token/0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc",
    name: "PiplCoin",
    symbol: "PIPL",
    walletAddress: '0xc7d018cf9f5fd54d466321cf9398420c3bd26fca',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 261797900,
    chart: "https://coinmarketcap.com/dexscan/polygon/0xc7d018cf9f5fd54d466321cf9398420c3bd26fca/",
    chart1: "https://dexscreener.com/polygon/0x38e6d06cd9c561213a32ce980c13e24683fb7f62",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'G43J4K2HTUD7DZTMVUNWPNS19H4BBJWCEN',
    price:{
        56:0.01,
        137:1,
        }
  },
  GetonCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
    chainExplorer: "https://polygonscan.com/token/0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
    name: "GetonCoin",
    symbol: "GETON",
    walletAddress: '0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0x1b9d6a5fc2382a97777fc56cebb109bca815c3be',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 1542000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9/",
    chart1: "https://dexscreener.com/polygon/0xb5ad0e4335d8e92e9008058c72b94779c381e85a",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: '55K63YUSMCUSKPJ2RXGFJPBEGCNNXD91CA',
    price:{
        56:0.01,
        137:1,
        }
  },
  GroshCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/GROSH.svg",
    chainExplorer: "https://polygonscan.com/token/0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220",
    name: "GroshCoin",
    symbol: "GROSH",
    walletAddress: '0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 210000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b/",
    chart1: "https://dexscreener.com/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'EVXHEG39R4CAW1PSSNB5SS4V9XCSY791V7',
    price:{
        56:0.01,
        137:1,
        }
  },
  PronCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/PRON.svg",
    chainExplorer: "https://polygonscan.com/token/0x2a6af8da13b36f925632a404a3161fe344d8f469",
    name: "PronCoin",
    symbol: "PRON",
    walletAddress: '0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24',
    wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
    tokenAddress1: '0x2a6af8da13b36f925632a404a3161fe344d8f469',
    tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 6,   // Decimals for tokenAddress2
    totalSupply: 69000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24/",
    chart1: "https://dexscreener.com/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN',
    price:{
        56:0.01,
        137:1,
        }
  },
  LifeOnScreen: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/LOS.svg",
    chainExplorer:
      "https://polygonscan.com/token/0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
    name: "LifeOnScreen",
    symbol: "LOS",
    walletAddress: "0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9",
    wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
    tokenAddress1: "0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
    tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenDecimals1: 18, // Decimals for tokenAddress1
    tokenDecimals2: 6, // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9/",
    chart1: "#",
    chainId: 137, // Polygon Mainnet chain ID
    network: "Polygon Mainnet", // Name of the network
    rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
    currencySymbol: "POL", // Currency symbol
    blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
    apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
  },
  CarbCoin: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/CARB.svg",
    chainExplorer:
      "https://polygonscan.com/token/0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
    name: "CarbCoin",
    symbol: "CARB",
    walletAddress: "0x7401933b0852287637aEa48d87cA8Ec4359A9509",
    wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
    tokenAddress1: "0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
    tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenDecimals1: 18, // Decimals for tokenAddress1
    tokenDecimals2: 6, // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0xdd5755f257dbebc82780bf66888acafbd94da77d/",
    chart1: "#",
    chainId: 137, // Polygon Mainnet chain ID
    network: "Polygon Mainnet", // Name of the network
    rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
    currencySymbol: "POL", // Currency symbol
    blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
    apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
  },
  CryptoBoard: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/CBDX.svg",
    chainExplorer:
      "https://polygonscan.com/token/0x7607Cb4a4538a1a90501bc919F495889b005A63b",
    name: "CryptoBoard",
    symbol: "CBDX",
    walletAddress: "0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd",
    wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
    tokenAddress1: "0x7607Cb4a4538a1a90501bc919F495889b005A63b",
    tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenDecimals1: 18, // Decimals for tokenAddress1
    tokenDecimals2: 6, // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd/",
    chart1: "#",
    chainId: 137, // Polygon Mainnet chain ID
    network: "Polygon Mainnet", // Name of the network
    rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
    currencySymbol: "POL", // Currency symbol
    blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
    apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
  },
  AirPod: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/APOD.svg",
    chainExplorer:
      "https://polygonscan.com/token/0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
    name: "AirPod Token",
    symbol: "APOD",
    walletAddress: "0x1401483F86940E3a2ED890fa75c01E3D8A099A31",
    wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
    tokenAddress1: "0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
    tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenDecimals1: 18, // Decimals for tokenAddress1
    tokenDecimals2: 6, // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x1401483F86940E3a2ED890fa75c01E3D8A099A31/",
    chart1: "#",
    chainId: 137, // Polygon Mainnet chain ID
    network: "Polygon Mainnet", // Name of the network
    rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
    currencySymbol: "POL", // Currency symbol
    blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
    apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
  },
  EuroGrosh: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/eGROSH.svg",
    chainExplorer:
      "https://polygonscan.com/token/0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
    name: "EuroGrosh",
    symbol: "eGROSH",
    walletAddress: "0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e",
    wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
    tokenAddress1: "0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
    tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenDecimals1: 18, // Decimals for tokenAddress1
    tokenDecimals2: 6, // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "https://coinmarketcap.com/dexscan/polygon/0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e/",
    chart1: "https://dexscreener.com/bsc/0x9ada8be10b186daba6a5c8970bbe7f7785fcc8bd",
    chainId: 137, // Polygon Mainnet chain ID
    network: "Polygon Mainnet", // Name of the network
    rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
    currencySymbol: "POL", // Currency symbol
    blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
    apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
  },
  DAOToken: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/DAO.svg",
    chainExplorer: "https://polygonscan.com/token/0x8053563b0678a2Cd900BAbc8756cC55e25685215",
    name: "DAOToken",
    symbol: "DAO",
    walletAddress: 'OX',
    wallet: '0X',
    tokenAddress1: '0x8053563b0678a2Cd900BAbc8756cC55e25685215',
    tokenAddress2: '0X',
    tokenDecimals1: 0,  // Decimals for tokenAddress1
    tokenDecimals2: 0,   // Decimals for tokenAddress2
    totalSupply: 1000000,
    chart: "#",
    chainId: 137,  // Polygon Mainnet chain ID
    network: 'Polygon Mainnet',  // Name of the network
    rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
    currencySymbol: 'POL',  // Currency symbol
    blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
    apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE',
    price:{
        56:0,
        137:0,
        }  
    },
WaveShare: {
    assetLogo: "https://tokens.waveswaps.com/images/tokens/WSHARE.svg",
    chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e",
    name: "WaveShare",
    symbol: "WSHARE",
    walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
    wallet: '0x51C2b96740aa4591E84747522559B870e3F531C3',
    tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
    tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    tokenDecimals1: 18,  // Decimals for tokenAddress1
    tokenDecimals2: 18,   // Decimals for tokenAddress2
    totalSupply: 100,
    chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
    chart1: "https://dexscreener.com/polygon/",
    chainId: 56,  // SmartChain ID
    network: 'BNB Smart Chain',  // Name of the network
    rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
    currencySymbol: 'BNB',  // Currency symbol
    blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
    apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
    price:{
        56:0.01,
        137:1,
        }
    },
    Tether: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/USDT.svg",
      chainExplorer:
        "https://polygonscan.com/token/0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      name: "Tether",
      symbol: "USDT",
      walletAddress: "0x",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      tokenAddress2: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
      tokenDecimals1: 6, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 776871798,
      chart: "https://coinmarketcap.com/currencies/tether/",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    USDCoin: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/USDC.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      name: "USD Coin",
      symbol: "USDC",
      walletAddress: "0x",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      tokenAddress2: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      tokenDecimals1: 6, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 369185472,
      chart: "https://coinmarketcap.com/currencies/usd-coin/",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },

    // Add other assets similarly...
  },

  CRAFT_DICT: {
    // Token Type 2 Crafting Recipes
    "1_1_10_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_20_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_30_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_40_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_50_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_60_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_70_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
    "1_1_80_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],

    // Token Type 3 Crafting Recipes
    "2_1_10_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_20_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_30_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_40_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_50_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_60_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_70_0": ["https://node.waveswaps.com/node2.png", 3],
    "2_1_80_0": ["https://node.waveswaps.com/node2.png", 3],

    // Token Type 4 Crafting Recipes
    "3_1_10_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_20_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_30_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_40_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_50_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_60_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_70_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
    "3_1_80_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],

    // Token Type 5 Crafting Recipes
    "4_1_10_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_20_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_30_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_40_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_50_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_60_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_70_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
    "4_1_80_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],

    // Token Type 6 Crafting Recipes
    "5_1_10_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_20_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_30_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_40_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_50_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_60_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_70_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
    "5_1_80_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
  },

  gblAddresses: {
    1: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
    10: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
    42161: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
    137: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
    56: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
  },
  quoterAddress: {
    1: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    10: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    42161: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    137: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    56: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  },
  //1388023 polygon 0.318938937766619260
  globalTokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
  globalTokenAddressEthereum: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
  globalTokenAddressOptimism: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
  voteGlobalTokenAddress: "0x2380B4d49094F0474E1adAeaDe94C5B789120549",

  genessisAddress: "0x8A802B796e8Dba2eC7e7Bf667fCe5d9eefC9d504",

  //waweSwapStorageAddressBsc: "0xe10F9d9e151139cD6cf55e5abcf78f8378B07c8F",
  waweSwapStorageAddressBsc: "0x97241A630F08FbF87230a03D39Eb9B6F2f8a6531",
  waweSwapAddressBsc: "0x4951EEeD26DC03fC0F5E6BD47e80df85F1420Bc7",
  ////waweSwapAddressBsc: "0xb04eb7e2D20dd19BAEeFdc9dEb902C11b1cF3434",
  ///waweSwapAddressBsc: "0x0761f6A27BD94214590fB951BdE00966Ad980296",
  //waweSwapAddressBsc: "0xdC25d696517bd9001C11bC8CB4D726AeE3885C69",
  waweSwapAddressBscOld: "0x2148F73c41B29eb2Cb189d0879f012e10196CC1b",
  //waweSwapStorageAddressBsc: "0x8eE6CA02634cE9e4A31146e2d0FBCAB26F4335F4",
  //waweSwapAddressBsc: "0x2df4102A5C19E3aD82cc1c2Bb6dd5301892e88d5",
  //-waweSwapStorageAddressBsc: "0x5B23DB0c9e82cC65DE73FB995034b63AB4Eb0445",
  //-waweSwapAddressBsc: "0x05077B9B661Fe943864e9433ee6EEe1740cAFE38",
  ///waweSwapAddressBsc: "0xe145ca31760cdabaC2518D05aD5A02d6E4DABb74",
  //waweSwapAddressBsc: "0xf480d846269EF24456f7bF035d3Cc64DdBFD53a5",
  //waweSwapAddressBsc: "0x148d8119e181B13dFa2D2E15a6559cC384041540",
  waweSwapStorageAddressOptimism: "0xF46081f32f603326CC17D47E67146b87AA6eaCCe",
  //waweSwapStorageAddressOptimism: "0x00dE74d36254f69a6fADb40901E9625E44C2392E",
  waweSwapAddressOptimism: "0x575799558411baA63CB8128619dEEDCD9c49161D",
  /////waweSwapAddressOptimism: "0x370958ec82d3180dBD7B765d1082c5F6bbd0DDdD",
  //waweSwapAddressOptimism: "0xe776D5c28C5a3692464575FeAA392141092e786F",
  ////waweSwapStorageAddressOptimism: "0x6A2D645a450577B8A031512142f33BB0fBa8397c",
  ////waweSwapAddressOptimism: "0xE772A0D39CD67B8b7c5dFdB7d632c1a23b8F629e",
  //waweSwapStorageAddressOptimism: "0x2061F153a409E016dFae22A6bb4f39cf4d093066",
  //waweSwapAddressOptimism: "0xa545cB6B7D1dAE6BF18eC73e47bC8bF814FcFe04",
  ////waweSwapAddressOptimism: "0xC49e4D954E97eCEbc020Ed096bDA568F35212191",
  //waweSwapAddressOptimism: "0xBC064cD7504d8e9F12Acfe637fAB326c9c5C9674",
  waweSwapStorageAddressArbitrum: "0x7F095e3E8a444682123153f48731470069761c9C",
  //waweSwapStorageAddressArbitrum: "0x0aB31036D3ab327A99532F7f4d13eA2B55Cf8504",
  waweSwapAddressArbitrum: "0x82Fdb77f5382b1b6f9dd084CF49A7AaBB957F9b9",
  /////waweSwapAddressArbitrum: "0x3BFcC6C10A54FEfB4bd01966E5c031915a91F38A",
  //waweSwapAddressArbitrum: "0x2f7BF78F9f272F80Fc790Df7a6C2d7dF3a78Bf53",
  ////waweSwapStorageAddressArbitrum: "0x92d94bDbc70be0CeaaC0E84a9268dEe197D8ea53",
  ////waweSwapAddressArbitrum: "0x0C7aE73c29784aDdC58ff6F282ff184210130aD2",
  //waweSwapStorageAddressArbitrum: "0xe776D5c28C5a3692464575FeAA392141092e786F",
  //waweSwapAddressArbitrum: "0x3eA7399aE2845B399fF62E865Ffb69a5C8066935",
  ////waweSwapAddressArbitrum: "0x3eA7399aE2845B399fF62E865Ffb69a5C8066935",
  //waweSwapAddressArbitrum: "0xb67F86834c446EaBD98f5430a012AFcDF4078Ed7",
  waweSwapStorageAddressPolygon: "0x9B204a1e797E24f9d12123AfA16DB31bDD41cd90",
  ///waweSwapStorageAddressPolygon: "0x52e38A3E70DA0a9Ff807137b6C32A77A1e78FDf3",
  //waweSwapStorageAddressPolygon: "0xbDa9f23f18CF924E42E3220f0854B51d585da2d0",
  waweSwapAddressPolygon: "0x8E0378f1dc7d45A582392E925B1aE85980bBe30f",
  /////waweSwapAddressPolygon: "0x117E813Fb433b7e55f66480Ed28325c3e3F04403",
  ///waweSwapAddressPolygon: "0xc5E7FAAff3bB0eEC973c8b147583BC133a45c0C2",
  ///waweSwapAddressPolygon: "0x48b9b7761f6bc5c3e560350b9D7c904FF8e9E5DF",
  //waweSwapAddressPolygon: "0x02f7e0152bBbbe5c70c7b0757Afffd61617d4362",
  //waweSwapAddressPolygon: "0x2F07c648E1B38B1ac5F82843bA83339b46C33D7B",
  //waweSwapAddressPolygon: "0xB38331857ea39047d5ba1d9ae720A329aea4dAeF",
  waweSwapAddressPolygonOld: "0xc5E7FAAff3bB0eEC973c8b147583BC133a45c0C2",
  ////waweSwapStorageAddressPolygon: "0x07ee351CECB7fac821098527F826a60BD3e400C3",
  ////waweSwapAddressPolygon: "0x5214F6505E5E19E61bEe513288FD5d2Cf3f91B9B",
  //waweSwapStorageAddressPolygon: "0xB764e80c6332fdCF756B7a4D24912F2C3350E38C",
  //waweSwapAddressPolygon: "0x5347AaA0E83C195a34FD6cA299ec6C23C2e9c704",
  ////waweSwapAddressPolygon: "0xAC810b20183E261b72a5B3010D8235E73F81c0e6",

  recycleSwapStorageAddress: "0x2356580d672dA895937bd9B443740D5Cba510181",
  recycleSwapAddress: "0x59F2F4fe3E90813D7C4924C2dfAB5f33c7E4c358",
  /////recycleSwapAddress: "0x68329CC567797cE3EF131A297355283A4DeE3452",
  ///recycleSwapAddress: "0xc7F40E00caC8F7465D12e1d1466f95489C6b9F56",

  //-recycleSwapStorageAddress: "0x2B27c8833fDB81C2e2a98b39204f6Cb227db6a25",
  //-recycleSwapAddress: "0x5a55A3EBaa5682E059371997b2Bd047F4E46277A",

  ////recycleSwapStorageAddress: "0x8ABA782b105154F6fd20b05Fc44DF88F67519B54",
  ////recycleSwapAddress: "0x6c9005ce4d83F2b86E3380E2a441d38109710806",
  //recycleSwapAddress: "0xdcBA989411a1CD7CFFf49D0812ea2AD4036d9EE4",
  //recycleSwapAddress: "0x8b14bA81d741570DD690147fCDf184dc93D41d8B",
  //recycleSwapAddress: "0xFEaC57c5C6B909389e59691A6D4eb89144E5105a",
  //recycleSwapAddress: "0x7c6023c91692E4C037700c4e783d49FDA99Ee2F0",

  waweSwapPolygonStorageAddress: "0x0EAfB43088891A78b3a70cf5e3188f64C3A44684",
  //waweSwapPolygonStorageAddress: "0x42a19B09C992f36264C424A9Ff8FF2dc64413d52",
  waweSwapPolygonAddress: "0x5e801c790CA1b768166c1aA198C6C51588BF2e74",
  //////waweSwapPolygonAddress: "0x1281B4863Da7CEE206B2b073dBe484bff599f934",
  ///waweSwapPolygonAddress: "0xDc7D4aaA6CE892DE64CF13CA5Bc9FD00d84b1f9F",
  //waweSwapPolygonAddress: "0x9e70D8b4d6BcCa182Ff00085d57Ad66A7f75efF3",
  //waweSwapPolygonAddress: "0xe536D3E8A44103fC3Fe7af08Fb9e27128dFfC417",
  //waweSwapPolygonAddress: "0xcD07c912aFDdAE760f9C6AABCDDF0440e230b834",
  //waweSwapPolygonAddress: "0x697e62692e7C5299a7133bD03F435b2df6bf19aC",

  waweSwapStorageAddress: "0xB14204DdB5d7328B9D777387E86D64556a03a092",
  waweSwapAddress: "0x89989F889B8B998242D14368136B62299bf7bC6d",
  //waweSwapAddress: "0xd843DAa5d5d6063534B20482a564BE988bE42C7f",
  //waweSwapStorageAddress: "0x5d67D0FbFda4E3C73ed608D3DfAFBc68d7DB4eDd",
  //waweSwapAddress: "0x66c7D844a0e2C771Cb602FFDeB292a847912742D",
  ////waweSwapStorageAddress: "0x57B7e25B8b919c300aBB73ff9C74C68FA371ac06",
  ////waweSwapAddress: "0x86784981766c073eFCBEd37613D958B6A71001e2",

  //*waweSwapStorageAddress: "0x9e9E63bf0620f7ecef22838fFEE19B9D9673dDE4",
  //*waweSwapAddress: "0xf82413B4C0D2ecd8c9aBA6fFC38dCFC95a933CCD",
  //waweSwapAddress: "0x01a411398302831F5251fCe0c47EaF834bD3c674",
  //waweSwapAddress: "0x5d719Ecb1cb2B8fCC8f5E2FF4Dc2eb64DE251F45",
  //waweSwapAddress: "0x04d0EEb8728889e6EF42D30e8799acb3C2eecDB8",
  //waweSwapAddress: "0xbE3749C050Fcd9aDE26468cDa61dEc6e15e8a404",
  ////waweSwapAddress: "0xa6F5C0Db41135bD6057B75E2db8DaE741357804A",
  //waweSwapAddress: "0x2F564859a7E3d9A25a5c31A51Ad5210E9c2329a5",
  ////waweSwapAddress: "0xC422D41f2338ACe08191150a4Ed8B9C63144BC49",
  //waweSwapAddress: "0xB3CE907af361025d3fc4BD42b253aD6e52FE12Fc",
  //waweSwapAddress: "0xac4C7B0f984619FC1c6aed404e80614206144e2B",
  //waweSwapAddress: "0x9Ae12aED6615f5eD85B1Ce016D836D3fE66Ccb2C",
  tokenSwapAddress: "0xa61C9184d9FA8Db83499B6dcf91752292CE338E4",
  //tokenSwapAddress: "0xe3a6e7a0fD221406BAFF18e47ff8f7e22B23d1B9",
  //tokenSwapAddress: "0x6060B9E8ad21A79e9F0c44B79Dc435a142b676B7",
  stakeAddress: "0xb94E0e205d82D2fc339B5b9dc338b7806041A1e2",
  //stakeAddress: "0x5e84F829e6666E2EECd5385866f41Ba8B8f94C02",
  stakeLinkedListAddress: "0x676066F654Ba5fbDD8F05d0142f022daBa5405a2",

  ownerAddress: "0xcFbA2075F6F4bd815aef59b8554e17a20da67698",
  stakeholdersAddress: "0xE0bda672b329b5eEe50fa5B852C6F489D9f55e6B",
  bridgeAddress: "0xc8840755b1d6f1b931F16D7297c08Fe6Bf1d743f",
  //bridgeAddress: "0x577037f0791647A02eCaBFb2f6A5CaA8a919EEaf",

  loanAddress: "0x4E02dC7498BF0758B7f9732926305d5c5560C63f",
  //loanAddress: "0x504084bABE95A4FD24D6FA68F98c1BD297CCD54a",
  //loanAddress: "0xfce0F0E873cB5743B292ABBfF6b2Ab4aD1d163E0",
  //loanAddress: "0xb7D18F7617852140204A3e1fFe88CBC821883edc",

  givePositionsContract: "0x1760E559079162dc3ccC9A7040dEB6Cf983fda38",
  getPositionsContract: "0xAC23FF2fC3Ef72464a38438988d08D41f06e22CD",
  getPositionsOldContract: "0xe1f810b402DCa46339A39E586b153c4be27f66B1",
  systemFeeAddress: "0xD841ED0960910beF64E72Aff0B460DAE97DC7DdC",
  lotteryAddress: "0x36430479772239C271dC7bb3ea2eFCc54Ef221EB",

  directExchangeAddress: "0x42a19B09C992f36264C424A9Ff8FF2dc64413d52",
  treasuryAddress: "0xdAB33a1fF52B95C76EfDE21B7f7E29778Aa47DB8",

  distributerContract: "0xF3e5FC25386173fe895290A81118e2b85cC44685",
  //distributerContract: "0x07945215A21F1A1A4B0f3c86Dd2e4BCc01449933",

  halvingWallet64: "0xB1fb8077767349f82858c456c57E9AD714e518c7",
  halvingWallet32: "0x6C845Bc1d0196F1f285056C85A385fA5Bc50dB2A",
  halvingWallet16: "0xdF8D47fD2932C943bCDED3439c3316Ed8E3D4E9e",
  halvingWallet8: "0x8c7dD813294b264f4A24Ef1896A993b85e5e0Bdf",

  openRatesLink: "https://docs.waweswaps.com/rates.html",
  backendUrl: "https://backend-prod.waweswaps.global",

  availableTokens: [
    "GETON",
    "ATE",
    "ARV",
    "META",
    "CODE",
    "GROSH",
    "eGROSH",
    "GMAAR",
    "PIPL",
    "CBDX",
    "CARB",
    "LOS",
    "PRON",
  ],

  exchangeTokens: [
    "BTCB",
    "ETH",
    "SOL",
    "XRP",
    "ADA",
    "DOT",
    "Cake",
    "SHIB",
    "GETON",
    "ATE",
    "ARV",
    "META",
    "CODE",
    "GROSH",
    "eGROSH",
    "GMAAR",
    "PIPL",
    "CBDX",
    "CARB",
    "LOS",
    "PRON",
  ],
  isSwapApp: true,
  isLoanApp: true,
  isTest: false,
  isDirectApp: false,
  isLotteryApp: false,
  isBuySellApp: false,
  maintenanceMode: false,
  maintenancePassword: "gYd1sd12332fdwfdsf",
  dexLinks: {
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    10: "https://app.uniswap.org/swap?chain=optimism&inputCurrency=0x94b008aA00579c1307B0EF2c499aD98a8ce58e58&outputCurrency=",
    42161:
      "https://app.uniswap.org/swap?chain=arbitrum&inputCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9&outputCurrency=",
    137: "https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=",
    56: "https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=",
  },
  scanLinks: {
    1: "https://etherscan.io/tx/",
    10: "https://optimistic.etherscan.io/tx/",
    42161: "https://arbiscan.io/tx/",
    137: "https://polygonscan.com/tx/",
    56: "https://bscscan.com/tx/",
  },
  revokeLinks: {
    1: "https://etherscan.io/tokenapprovalchecker",
    10: "https://optimistic.etherscan.io/tokenapprovalchecker",
    42161: "https://arbiscan.io/tokenapprovalchecker",
    137: "https://polygonscan.com/tokenapprovalchecker",
    56: "https://bscscan.com/tokenapprovalchecker",
  },
  networkIcons: {
    1: "https://docs.waweswaps.com/images/chains/ethereum.svg",
    10: "https://docs.waweswaps.com/images/chains/optimism.svg",
    137: "https://docs.waweswaps.com/images/chains/polygon.svg",
    56: "https://docs.waweswaps.com/images/chains/smartchain.svg",
    42161: "https://docs.waweswaps.com/images/chains/arbitrum.svg",
  },
  usdtAddress: {
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    10: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    56: "0x55d398326f99059fF775485246999027B3197955",
  },
  bridgeFees: {
    1: "0.1",
    10: "0.001",
    42161: "0.001",
    137: "2",
    56: "0.005",
  },

  buyTokensDataEthereum: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
    },
    {
      name: "WBTC",
      symbol: "Wrapped BTC ",
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    },
    {
      name: "USDT",
      symbol: "USDT",
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    },
  ],
  buyTokensDataPolygon: [
    {
      name: "MATIC",
      symbol: "MATIC",
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    },
    /* {"name":"BTCBToken","symbol":"BTCB","address":"0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6"},
          {"name":"EthereumToken","symbol":"ETH","address":"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"},
          {"name":"USDT","symbol":"USDT","address":"0xc2132D05D31c914a87C6611C10748AEb04B58e8F"},*/
  ],
  buyTokensDataArbitrum: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    },
    /*{"name":"BNB","symbol":"BNB","address":"0x20865e63B111B2649ef829EC220536c82C58ad7B"},
          {"name":"WBTC","symbol":"WBTC","address":"0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f"},
          {"name":"USDT","symbol":"USDT","address":"0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"},*/
  ],
  buyTokensDataOptimism: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x4200000000000000000000000000000000000006",
    },
    /* {"name":"WBTC","symbol":"WBTC","address":"0x68f180fcCe6836688e9084f035309E29Bf0A2095"},
          {"name":"USDT","symbol":"USDT","address":"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58"},*/
  ],
  buyTokensData: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    /*{"name":"BTCB","symbol":"BTCB","address":"0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c"},
          {"name":"EthereumToken","symbol":"ETH","address":"0x2170Ed0880ac9A755fd29B2688956BD959F933F8"},
          {"name":"Solana","symbol":"SOL","address":"0x570A5D26f7765Ecb712C0924E4De545B89fD43dF"},
          {"name":"USDT","symbol":"USDT","address":"0x55d398326f99059fF775485246999027B3197955"},
          {"name":"Cardano","symbol":"ADA","address":"0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"},
          {"name":"XRPToken","symbol":"XRP","address":"0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE"}*/
  ],
  tokensData: [
    {
      name: "Pron Coin",
      symbol: "PRON",
      address: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
      price: 100,
    },
    {
      name: "Geton Coin",
      symbol: "GETON",
      address: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
      price: 0.1,
    },
    //{"name":"LifeOnScreen","symbol":"LOS","address":"0xc1463935d11EB0C9a06530F387077053Ebe4500B","price":1},
    //{"name":"CarbCoin","symbol":"CARB","address":"0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d","price":0.1},
    //{"name":"ATON EVOLUTION","symbol":"ATE","address":"0xc8d9d70C1E1C1aebA75CCeF338776f27C451377B","price":1000},
    //{"name":"CryptoBoard Token","symbol":"CBDX","address":"0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E","price":1},
    //{"name":"ATONReversalVoucher","symbol":"ARV","address":"0xC01419523900ACCD39EBA74c5a1B6909154F99a3","price":100},
    {
      name: "Pipl Coin",
      symbol: "PIPL",
      address: "0xE12723848699AdcbFF466050cE17Cbf5C6156720",
      price: 0.1,
    },
    //{"name":"GMAAR Token","symbol":"GMAAR","address":"0x7A81eeB086e4502bb8A022F804831575db42Fa07","price":0.001},
    //{"name":"EuroGrosh","symbol":"eGROSH","address":"0x542e258550dC85EdE56D9480Bc6A68c07158ed24","price":100},
    {
      name: "Grosh Coin",
      symbol: "GROSH",
      address: "0xb67B9a92D902F8beadEe9846CDde40a10F198808",
      price: 0.01,
    },
    {
      name: "CryptoCode",
      symbol: "CODE",
      address: "0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942",
      price: 1,
    },
    {
      name: "MetaCoin",
      symbol: "META",
      address: "0xfe1E4B7515b933faAF7B32e569eB245b7542b03C",
      price: 0.001,
    },
  ],

  tokensDataPolygon: [
    {
      name: "PiplCoin",
      symbol: "PIPL",
      address: "0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
      price: 100,
    },
    {
      name: "PronCoin",
      symbol: "PRON",
      address: "0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
      price: 100,
    },
    {
      name: "GroshCoin",
      symbol: "GROSH",
      address: "0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
      price: 100,
    },
    {
      name: "GetonCoin",
      symbol: "GETON",
      address: "0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
      price: 100,
    },
    {
      name: "MetaCoin",
      symbol: "META",
      address: "0xD51E7065B942D5A5886915C6BEbf42600D546162",
      price: 100,
    },
    {
      name: "CryptoCode",
      symbol: "CODE",
      address: "0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
      price: 100,
    },
  ],
  tokensDataV1: [
    {
      name: "Pron Coin",
      symbol: "PRON",
      address: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
      price: 100,
    },
    {
      name: "Geton Coin",
      symbol: "GETON",
      address: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
      price: 0.1,
    },
    {
      name: "LifeOnScreen",
      symbol: "LOS",
      address: "0xc1463935d11EB0C9a06530F387077053Ebe4500B",
      price: 1,
    },
    {
      name: "CarbCoin",
      symbol: "CARB",
      address: "0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
      price: 0.1,
    },
    {
      name: "ATON EVOLUTION",
      symbol: "ATE",
      address: "0xc8d9d70C1E1C1aebA75CCeF338776f27C451377B",
      price: 1000,
    },
    {
      name: "CryptoBoard Token",
      symbol: "CBDX",
      address: "0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
      price: 1,
    },
    {
      name: "ATONReversalVoucher",
      symbol: "ARV",
      address: "0xC01419523900ACCD39EBA74c5a1B6909154F99a3",
      price: 100,
    },
    {
      name: "Pipl Coin",
      symbol: "PIPL",
      address: "0xE12723848699AdcbFF466050cE17Cbf5C6156720",
      price: 0.1,
    },
    {
      name: "GMAAR Token",
      symbol: "GMAAR",
      address: "0x7A81eeB086e4502bb8A022F804831575db42Fa07",
      price: 0.001,
    },
    {
      name: "EuroGrosh",
      symbol: "eGROSH",
      address: "0x542e258550dC85EdE56D9480Bc6A68c07158ed24",
      price: 100,
    },
    {
      name: "Grosh Coin",
      symbol: "GROSH",
      address: "0xb67B9a92D902F8beadEe9846CDde40a10F198808",
      price: 0.01,
    },
    {
      name: "CryptoCode",
      symbol: "CODE",
      address: "0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942",
      price: 1,
    },
    {
      name: "MetaCoin",
      symbol: "META",
      address: "0xfe1E4B7515b933faAF7B32e569eB245b7542b03C",
      price: 0.001,
    },
  ],

  loanTokensData: [
    {
      name: "BTCBToken",
      symbol: "BTCB",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      token_amount: 0.05,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "EthereumToken",
      symbol: "ETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      token_amount: 0.5,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "PronCoin",
      symbol: "PRON",
      address: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
      token_amount: 5,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "WrappedSOL",
      symbol: "SOL",
      address: "0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e",
      token_amount: 50,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "GetonCoin",
      symbol: "GETON",
      address: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
      token_amount: 50,
      gbl_amount: 5000,
      balance: 2092.98,
    },
    {
      name: "LifeOnScreen",
      symbol: "LOS",
      address: "0xc1463935d11EB0C9a06530F387077053Ebe4500B",
      token_amount: 100,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "CarbCoin",
      symbol: "CARB",
      address: "0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
      token_amount: 250,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "XRPToken",
      symbol: "XRP",
      address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "ATONReversalVoucher",
      symbol: "ARV",
      address: "0xC01419523900ACCD39EBA74c5a1B6909154F99a3",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 1,
    },
    {
      name: "CardanoToken",
      symbol: "ADA",
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "PolkadotToken",
      symbol: "DOT",
      address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "PancakeSwapToken",
      symbol: "Cake",
      address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "CryptoBoardToken",
      symbol: "CBDX",
      address: "0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
      token_amount: 500,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "PiplCoin",
      symbol: "PIPL",
      address: "0xE12723848699AdcbFF466050cE17Cbf5C6156720",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "CryptoCode",
      symbol: "CODE",
      address: "0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "EuroGrosh",
      symbol: "eGROSH",
      address: "0x542e258550dC85EdE56D9480Bc6A68c07158ed24",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "GroshCoin",
      symbol: "GROSH",
      address: "0xb67B9a92D902F8beadEe9846CDde40a10F198808",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "AirPodToken",
      symbol: "APOD",
      address: "0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "GMAARToken",
      symbol: "GMAAR",
      address: "0x7A81eeB086e4502bb8A022F804831575db42Fa07",
      token_amount: 5000,
      gbl_amount: 5000,
      balance: 0,
    },
    {
      name: "SHIBAINU",
      symbol: "SHIB",
      address: "0xb1547683DA678f2e1F003A780143EC10Af8a832B",
      token_amount: 49999.99999999999,
      gbl_amount: 5000,
      balance: 13.10815536044723,
    },
    {
      name: "MetaCoin",
      symbol: "META",
      address: "0xfe1E4B7515b933faAF7B32e569eB245b7542b03C",
      token_amount: 5000000,
      gbl_amount: 5000,
      balance: 100,
    },
  ],
  tokenIcons: {
    MATIC:
      "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.2.2.0",
    BNB: "https://bscscan.com/assets/bsc/images/svg/logos/bnb-token.svg?v=23.9.3.0",
    USDT: "https://tokens.pancakeswap.finance/images/symbol/usdt.png",
    WBNB: "https://bscscan.com/assets/bsc/images/svg/logos/bnb-token.svg?v=23.9.3.0",
    BTCB: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    WBTC: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    ETH: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    WETH: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    XRP: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    SOL: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    ADA: "https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png",
    PRON: "https://logos.waveswaps.com/assets/PRON.svg",
    GETON: "https://logos.waveswaps.com/assets/GETON.svg",
    LOS: "https://logos.waveswaps.com/assets/LOS.svg",
    CARB: "https://logos.waveswaps.com/assets/CARB.svg",
    ARV: "https://logos.waveswaps.com/assets/ARV.svg",
    DOT: "https://logos.waveswaps.com/assets/DOT.svg",
    CAKE: "https://logos.waveswaps.com/assets/CAKE.svg",
    WAVE: "https://logos.waveswaps.com/assets/WAVE.svg",
    CBDX: "https://logos.waveswaps.com/assets/CBDX.svg",
    PIPL: "https://logos.waveswaps.com/assets/PIPL.svg",
    GROSH: "https://logos.waveswaps.com/assets/GROSH.svg",
    GBL: "https://logos.waveswaps.com/assets/GBL.svg",
    VGBL: "https://logos.waveswaps.com/assets/VGBL.svg",
    Cake: "https://logos.waveswaps.com/assets/CAKE.svg",
    SHIB: "https://logos.waveswaps.com/assets/SHIB.svg",
    GMAAR: "https://logos.waveswaps.com/assets/GMAAR.svg",
    CODE: "https://logos.waveswaps.com/assets/CODE.svg",
    eGROSH: "https://logos.waveswaps.com/assets/eGROSH.svg",
    META: "https://logos.waveswaps.com/assets/META.svg",
    ATE: "https://logos.waveswaps.com/assets/ATE.svg",
    WMATIC:
      "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.2.2.0",
  },

  systemAddresses: [
    "0x81B4AB15712d7016CAE373B2D777D7A0DA518661",
    "0x4c85C2963e7f230B615F69D64B4aa5c836c73AD6",
    "0x2E1eA5bB889Bdf04E6dD14Fc05B3201D427Ca7fc",
    "0x7Ece9e6f590EFE65Cc076b24564E75EEa635228c",
    "0x74a11D83d60A0aa73a4d1104B2C2C79e2Daf4957",
    "0x28d650b28f19856BD3a7b93d85cFA117F38dF1D2",
    "0x4d1E35A73546b231006BFA52846729C7320eDf3E",
    "0x3ECbc55dB1a0543A01fF2d78e32514797963677D",
    "0xfDe51189BF635141c31AfA0bFF9A3480028F5425",
    "0xa9198bD5DbDb2460010dcE2d04763f1089a0B287",
    "0xD0A18BcCfAaE72795F1480785a15f1c1Ad1f6a6E",
    "0xb9d9a67ADbE2Cb3495bEA138C43b0e6C065E23e8",
    "0x0E04b09592d0A6B9b99b8ad053cF2Abd1eC3378f",
    "0xeEb333188d9Af797dD0c999Df7228532b05D2e4d",
    "0xB7b8457861928AF2D3da373c5d7423e69DC6F8f7",
    "0x302CB1FC7dd4716dF8C90fDeD251b566fC9B22a6",
    "0x9636bC2dEaD83Ae48AC4c1F7FF07d2061288Be86",
    "0x386F8D7f610a8Ef116db9385764b5182eF498824",
    "0xF9700b334e59e25F6c31f7C693Db570757313eb7",
    "0x31996ccB7f4fD2012Cc69A58bFf4471aA1363731",
    "0x2d7C62CA7F6919e8792ECA8978AA3ae4004DD03d",
    "0x4b4cF505CDbfc4a4864Ac7fe023825C7078A2140",
    "0x4668Fa11cB2309904808bB0984Fe5ca6AACbF2cC",
    "0x9013abc1535E13361b0f88B189F6005CbA37FE29",
    "0x306240C19abaC7C62cA942D6Ef8873Ac93A948dB",
    "0xa807F81b6e02960cE9B000d77D8b7D2574B31b14",
    "0x9496020Dc5266f955D2ba53e62dE5Dd64CcF80EC",
    "0x673D25d509e68E080d999c10f031111bA8Eecd73",
    "0x320c8121a54708a7AFa530a7D67E0a0B311E7291",
    "0x6676AE542A058FFeB89C020f8A7e539f67b8Ff1b",
    "0xC34C4e3e559410F3eD09634FfA1e776ba5FF7aC8",
    "0x3FbEf4eA46BADDC17A1Ae26E108FE5E32D8104A5",
    "0x6b673397E01c5c8Ce3DB92681e5DeD2889AB0760",
    "0x6842e7aeD8491A653b44E79402b5F9419e7ff7e6",
    "0x9272998ff0f19747820438fdBFF7E015b7484DD2",
    "0x56e4f2336e8AAa250950C0439c1A79f6f305F150",
    "0xDA34d47bBa2a22f90318c67F1a14bAC1ecC63c84",
    "0x3DAFC5BF9F3970Dcf713bC87943c7dd7A826edfB",
    "0x2deED5e3c103f32ff431b6D7C7231989872A22DE",
    "0xa324a475252417EB649c84f044e72247d4Aa4f62",
    "0x675199313bb6B68a5391E6d3E85EFaDA875f482c",
    "0xb047936969E5439a94D373c8C4B0c6E961D0d03E",
    "0x0DC3bc95Ae28531f538d6d5a490065a629296854",
    "0xA97eD2706eDD77087696E0BF5C3610824BFc0091",
    "0x9789758240eB1e5b2faB50e2ebe4D87fBd2439A7",
    "0x46cEEC85e15C1271AbE84505195b77367aB60055",
    "0xED9eAcFA6A528186E2b9bE344E3D1BA2273e8916",
    "0x8F1faae4cfaEe630AaBAa6A3600baF2C9F8056d1",
    "0x09ddD9a91916d51ca68A2739e992dcC1CF352A68",
    "0x8F2b174dB095D059E5646484b5DaA4196043e0c7",
    "0xa2F03Cf6a063b8de881DD4b6d2B245Bf791E746e",
    "0x06008e55845EBcF5D0E76973e001DE94d0CFe63A",
    "0xE655CCD10926cca9C9E1dCFB47C05fB104eF585a",
    "0xf3e7038FA587fA191D2B113f72A3fB651da6E83A",
    "0xdc5aBc16F3Ca5Df8D2141A8aE9692F8b96A9c43e",
    "0xe46371372C04546c0e2f707211903a89AdBdB71C",
    "0x8060E67eCC1E5fF7634afde03030c10b55Ba8B91",
    "0x4D62BD310645ef3464Ff4a9916C2243172bAd074",
    "0xAF5212272Fa6054C2AB89Ad1003Fe878D8DF75C4",
  ],
};

export default SETTINGS;
