// src/components/Dashboard.js

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Presale from './Presale';
import UserDashboard from './UserDashboard';
import ClaimTokens from './ClaimTokens';
import Listings from './Listings';

function PonziDashboard() {
  return (
    <div className="supplies-container mt-3 ml-1 mctable">

    <Listings />

    <h2 className="pageTitle supplies-header">Presale</h2>
    <p className="hero-p sub-header">Multi-chain live token supplies with real-time insights, enabling transparent asset monitoring and measuring the impact of recycling.</p> 

    <Row className="justify-content-center">
      <Col xs={12} md={6} lg={4} className="mb-4">
        <Presale />
      </Col>
      <Col xs={12} md={6} lg={4} className="mb-4">
        <UserDashboard />
      </Col>
      <Col xs={12} md={6} lg={4} className="mb-4">
        <ClaimTokens />
      </Col>
    </Row>
    </div>
  );
}

export default PonziDashboard;
