import React, { useState } from "react";
import { Modal, Button, Spinner, Badge } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa"; // For a warning icon
import 'bootstrap/dist/css/bootstrap.min.css';

const TokenWithdrawComponent = ({ amount, email }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);

  // Handle modal open/close
  const handleShowModal = async () => {
    setError(null);
    setSuccessMessage(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to claim tokens
  const claimPowerCores = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://backend.geton.global/claim_cores.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ email, amount }),
        }
      );
      const result = await response.json();

      if (result.status === "success") {
        setHasWithdrawn(true);
        setSuccessMessage("Transformation to WAVE Power Cores Successfully Executed!");
      } else {
        setError(result.message);
        setHasWithdrawn(true);
      }
      window.location.reload();
    } catch (error) {
      setError("An error occurred while processing your request. Please try again.");
      setHasWithdrawn(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Claim Button */}
      <Button
        onClick={handleShowModal}
        disabled={hasWithdrawn}
        variant="primary"
        className="btn btn-success w-100"
        style={{ padding: "10px 18px", fontSize: "12px", cursor: "pointer" }}
      >
        {hasWithdrawn ? (
          <>Transformation Already Initiated</>
        ) : (
          <>Transform to {parseFloat(amount).toLocaleString()} WAVE Power Cores</>
        )}
      </Button>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Transform to WAVE Power Cores
            {hasWithdrawn && <Badge bg="secondary" className="ms-2">Completed</Badge>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img
          src="https://node.waveswaps.com/node80.png"
          alt="WAVE COREs"
          style={{
            width: "80%",
            margin: "0 auto",
            display: "block",
            borderRadius: "16px"
          }}
        />
        <p className="mt-2">

            <strong>Receiving:</strong> {parseFloat(amount).toLocaleString()} WAVE Power Cores
          </p>
          <p>
            <strong>Status:</strong>{" "}
            {hasWithdrawn ? "Already Claimed" : "Ready to Initiate"}
          </p>
          {error && (
            <div className="alert alert-danger d-flex align-items-center mt-2">
              <FaExclamationTriangle className="me-2" />
              {error}
            </div>
          )}
          {successMessage && (
            <div className="alert alert-success mt-2">{successMessage}</div>
          )}
          <small className="text-muted d-block mt-3">
            Your selection is final. No changes or disputes will be accepted.
          </small>
        </Modal.Body>
        <Modal.Footer>
          <small className="smallText d-block text-start mb-2">
            By clicking "Submit," you agree with {" "}
            <a
              href="https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions.
            </a>
          </small>
          <Button
            variant="primary"
            onClick={claimPowerCores}
            disabled={isLoading || hasWithdrawn}
            style={{ width: "100%", fontSize: "16px" }}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ms-2">Processing...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TokenWithdrawComponent;
