// src/components/ponzigetonzi/Presale.js

import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import presaleContractInfo from '../../contracts/PresaleContract.json';
import { FaUserCircle } from 'react-icons/fa';
import {
  Button,
  Card,
  Form,
  Alert,
  Modal,
  ProgressBar,
} from 'react-bootstrap';

function Presale() {
  // State variables
  const [amountETH, setAmountETH] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [presaleStartTime, setPresaleStartTime] = useState(0);
  const [presaleEndTime, setPresaleEndTime] = useState(0);
  const [tokensSold, setTokensSold] = useState(0);
  const [totalCollectedETH, setTotalCollectedETH] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchPresaleData = async () => {
      try {
        // Initialize provider
        const provider = new BrowserProvider(window.ethereum);

        const presaleContract = new Contract(
          presaleContractInfo.address,
          presaleContractInfo.abi,
          provider
        );

        // Fetch data from the contract
        const [
          startTime,
          endTime,
          soldTokens,
          collectedETH,
          price,
        ] = await Promise.all([
          presaleContract.presaleStartTime(),
          presaleContract.presaleEndTime(),
          presaleContract.tokensSold(),
          presaleContract.totalCollectedETH(),
          presaleContract.tokenPrice(),
        ]);

        setPresaleStartTime(startTime.toNumber());
        setPresaleEndTime(endTime.toNumber());
        setTokensSold(formatUnits(soldTokens, 18));
        setTotalCollectedETH(formatUnits(collectedETH, 18));
        setTokenPrice(formatUnits(price, 18));

        // Calculate progress (assuming a hard cap of 1000 ETH)
        const hardCap = 1000;
        const progressPercentage =
          (parseFloat(formatUnits(collectedETH, 18)) / hardCap) * 100;
        setProgress(progressPercentage);
      } catch (error) {
        console.error('Error fetching presale data:', error);
      }
    };

    fetchPresaleData();
  }, []);

  const buyTokens = async () => {
    try {
      if (!amountETH || isNaN(amountETH) || parseFloat(amountETH) <= 0) {
        setStatusMessage('Please enter a valid amount of ETH.');
        return;
      }

      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Initialize provider and signer
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const presaleContract = new Contract(
        presaleContractInfo.address,
        presaleContractInfo.abi,
        signer
      );

      const txResponse = await presaleContract.buyTokens({
        value: parseUnits(amountETH, 18),
      });
      setStatusMessage('Transaction submitted. Waiting for confirmation...');
      await txResponse.wait();
      setStatusMessage('Tokens purchased successfully!');
      setShowModal(false);
    } catch (error) {
      console.error(error);
      setStatusMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <>
      <Card className="custom-card text-center">
        <Card.Header>
          <FaUserCircle size={32} /> Presale Progress
        </Card.Header>
        <Card.Body>
          <ProgressBar
            now={progress}
            label={`${progress.toFixed(2)}%`}
            className="progress-custom"
          />
          <div className="mt-4">
            <h5>Presale Details:</h5>
            <p>
              <strong>Start Time:</strong>{' '}
              {new Date(presaleStartTime * 1000).toLocaleString()}
            </p>
            <p>
              <strong>End Time:</strong>{' '}
              {new Date(presaleEndTime * 1000).toLocaleString()}
            </p>
            <p>
              <strong>Tokens Sold:</strong> {tokensSold}
            </p>
            <p>
              <strong>Total ETH Collected:</strong> {totalCollectedETH} ETH
            </p>
            <p>
              <strong>Token Price:</strong> {tokenPrice} ETH
            </p>
          </div>
          <Button variant="primary" className="mt-3" onClick={() => setShowModal(true)}>
            Participate Now
          </Button>
          {statusMessage && (
            <Alert variant="info" className="mt-3">
              {statusMessage}
            </Alert>
          )}
        </Card.Body>
      </Card>

      {/* Modal for buying tokens */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>Buy Tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="amountETH">
            <Form.Label>Amount of ETH to Contribute:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in ETH"
              value={amountETH}
              onChange={(e) => setAmountETH(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Button variant="primary" className="modal-button btn-custom" onClick={buyTokens}>
            Buy Tokens
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Presale;
