import React, { useEffect, useState } from "react";
import { BiCheckCircle, BiCopy, BiInfoCircle } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import SETTINGS_GETON from "../SETTINGS_GETON"; // Ensure the path to SETTINGS_GETON is correct
import MatrixEffect from "./MatrixEffect";
import DAOTokenComponent from "./DAOTokenComponent";
import TokenWithdrawComponent from "./TokenWithdrawComponent";
import { Spinner, Button } from "react-bootstrap";
import ConnectButton from "./ConnectButton";
import { FaCheckCircle, FaSpinner } from "react-icons/fa";

const GetonBalances = ({
  provider,
  address,
  isConnected,
  networkId,
  switchNetwork,
}) => {
  const [assetData, setAssetData] = useState({}); // State to store data for assets
  const [loading, setLoading] = useState(false); // Loading state
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [balances, setBalances] = useState(null);
  const [error, setError] = useState("");
  const [daoTokensToClaim, setDaoTokenToClaim] = useState(0);
  const [coresToClaim, setCoresToClaim] = useState(0);
  const [claimed, setClaimed] = useState(-1);
  const [isAddressLinked, setIsAddressLinked] = useState(false);
  const tokenIcons = [
    "PIPL",
    "GETON",
    "PRON",
    "CBDX",
    "GROSH",
    "LOS",
    "APOD",
    "CARB",
    "EGROSH",
  ];

  const prices = {
    PIPL: 0.000003,
    GETON: 0.00000028,
    PRON: 0.00000002,
    CBDX: 0.00006644,
    GROSH: 0.00000563,
    LOS: 0.00042172,
    APOD: 0.00000563,
    CARB: 0.00001309,
    EGROSH: 0.00000563,
  };

  const daoPrices = {
    PIPL: 1.82,
    GETON: 60,
    PRON: 960,
    CBDX: 8,
    GROSH: 1.2,
    LOS: 24,
    APOD: 1.2,
    CARB: 18,
    EGROSH: 1.2,
  };

  const decimals = {
    PIPL: 8,
    GETON: 8,
    PRON: 8,
    CBDX: 8,
    GROSH: 8,
    LOS: 8,
    APOD: 8,
    CARB: 8,
    EGROSH: 8,
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGetCores = async (_email) => {
    setError(null);
    setBalances(null);

    try {
      const response = await fetch(
        "https://backend.geton.global/get_cores.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({ email: _email }),
        }
      );

      const data = await response.json();
      console.log("data");
      console.log(data);
      if(data.claimed != null){
        setClaimed(data.claimed);
      }
    }catch (error) {
      console.log("An error occurred while fetching core data.");
    }
  }

  const handleGetBalances = async () => {
    setError(null);
    setBalances(null);

    try {
      const response = await fetch(
        "https://backend.geton.global/get_balances.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({ eth_address: address }),
        }
      );

      const data = await response.json();
      
      setEmail(data.email);
      await handleGetCores(data.email);
      let fullDaoAmount = 0;
      let fullCoresAmount = 0;
      const pData = await fetchAllData();
      for (let i = 0; i < tokenIcons.length; i++) {
        const am =
          (parseFloat(data.balances[tokenIcons[i]]) /
            10 ** decimals[tokenIcons[i]]) *
          daoPrices[tokenIcons[i]];

        fullDaoAmount += am;

        console.log(pData[tokenIcons[i]]?.price);
     
        const coreAm = ((pData[tokenIcons[i]]?.price * (parseFloat(data.balances[tokenIcons[i]]) / 10 ** decimals[tokenIcons[i]]))/30).toFixed(0);
        
        fullCoresAmount += parseInt(coreAm);
        
      }
      setDaoTokenToClaim(fullDaoAmount.toFixed(0));
      setCoresToClaim(fullCoresAmount);
      if (data.status === "success") {
        setBalances(data.balances);
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching balance data.");
    }
  };




  const handleSendCode = async () => {
    try {
      const response = await fetch(
        "https://backend.geton.global/generate_code.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ email }),
        }
      );
      const result = await response.json();
      if (result.status === "success") {
        setIsCodeSent(true);
        setError("");
      } else {
        setError(result.message);
        setIsCodeSent(true);
      }
    } catch (error) {
      setIsCodeSent(true);
    }
  };


  const handleVerifyCode = async () => {
    try {
      const response = await fetch(
        "https://backend.geton.global/verify_code.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ email, code, eth_address: address }), // Assuming `address` holds the Ethereum address
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        setBalances(result.balances);

        setError("");
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("Failed to verify code");
    }
  };

  const getApiUrlForNetwork = (
    network,
    tokenAddress,
    walletAddress,
    apiKey
  ) => {
    switch (network) {
      case "Polygon Mainnet":
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "BNB Smart Chain":
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error("Unsupported network:", network);
        return null;
    }
  };

  const fetchPrice = async (asset, key) => {
    const {
      tokenAddress1,
      tokenAddress2,
      walletAddress,
      tokenDecimals1,
      tokenDecimals2,
      apiKey,
      network,
    } = asset;
  
    const apiUrl1 = getApiUrlForNetwork(
      network,
      tokenAddress1,
      walletAddress,
      apiKey
    );
    const apiUrl2 = getApiUrlForNetwork(
      network,
      tokenAddress2,
      walletAddress,
      apiKey
    );
  
    if (!apiUrl1 || !apiUrl2) return { price: "Error" };
  
    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          fetch(apiUrl1),
          fetch(apiUrl2),
        ]);
        const data1 = await response1.json();
        const data2 = await response2.json();
        const balance1 = data1.result / Math.pow(10, tokenDecimals1);
        const balance2 = data2.result / Math.pow(10, tokenDecimals2);
        const price = (balance2 / balance1).toFixed(2);
  
        return parseFloat(price); // Parse to ensure it's a number
      } catch (error) {
        console.error("Error fetching price data for", key, error);
        return NaN; // Return NaN on error
      }
    };
  
    let price = await fetchData();
  
    // Retry mechanism if price is NaN
    if (isNaN(price)) {
      console.warn(`Price for ${key} is NaN, retrying...`);
      price = await fetchData(); // Retry once
    }
  
    // Final check
    if (isNaN(price)) {
      console.error(`Price for ${key} could not be determined after retry.`);
      return { price: "Error" };
    }
  
    return { price: price.toFixed(2) }; // Ensure consistent formatting
  };
  

  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const key of Object.keys(SETTINGS_GETON.TOKENS)) {
      const asset = SETTINGS_GETON.TOKENS[key];
      const priceData = await fetchPrice(asset, key);

     
      results[key] = { ...priceData };

      await delay(1000); // Delay between API calls to avoid rate limits
    }

    setAssetData(results);
    setLoading(false);
    return results;
  };

  useEffect(() => {
    if (isConnected) {
      fetchAllData();
      
      handleGetBalances();
 
    }
  }, [isConnected]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage,
    chainId,
    networkName,
    rpcUrl,
    currencySymbol,
    blockExplorerUrl
  ) => {
    try {
      if (typeof window.ethereum === "undefined") {
        alert(
          "MetaMask is not installed. Please install MetaMask to use this feature."
        );
        return;
      }

      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x" + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x" + chainId.toString(16),
                    chainName: networkName,
                    rpcUrls: [rpcUrl],
                    nativeCurrency: {
                      name: currencySymbol,
                      symbol: currencySymbol,
                      decimals: 18,
                    },
                    blockExplorerUrls: [blockExplorerUrl],
                  },
                ],
              });
            } catch (addError) {
              console.error("Error adding network:", addError);
              return;
            }
          } else {
            console.error("Error switching network:", switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(
          `${tokenSymbol} successfully added to MetaMask on ${networkName}`
        );
      } else {
        console.log("Token addition to MetaMask failed.");
      }
    } catch (error) {
      console.error("Error adding token to MetaMask:", error);
    }
  };

  const handleAddToken = (asset) => {
    const {
      tokenAddress1,
      symbol,
      tokenDecimals,
      assetLogo,
      chainId,
      network,
      rpcUrl,
      currencySymbol,
      blockExplorerUrl,
    } = asset;

    addTokenFunction(
      tokenAddress1,
      symbol,
      tokenDecimals,
      assetLogo,
      chainId,
      network,
      rpcUrl,
      currencySymbol,
      blockExplorerUrl
    );
  };

  return (
    <>{loading && (
      <div className="gameLoader">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="gameSpinner">
        <Spinner  color="#00AEEF" height={240} width={240} />
        </div>
        </div>
    )}
    <div className="container tokenstable">
      {!balances ? (
        <div className="card-fix text-center pt-4 card-100 mt-4">
          <MatrixEffect
            className="matrix-effect"
            quantity={10000}
            speed={0.05}
          />

          <h2 className="dashboard-header">Future of Geton Global</h2>
          <p className="hero-p sub-header" style={{ textAlign: "justify" }}>
            <b style={{ color: "#0B4182", fontSize: "1.1em" }}>
              Secure your token balances from Geton Ecosystem's merged platforms
              and claim your DAO tokens to participate in governance.
            </b>
            <br />
            (1) Connect your <b style={{ color: "#0B4182" }}>MetaMask</b>, (2)
            enter the <b style={{ color: "#0B4182" }}>email</b> you used to
            manage your Gx... wallet (GrossusBlockchain) on any of the merged
            platforms, (3) request an{" "}
            <b style={{ color: "#0B4182" }}> authentication code</b>, and
            confirm the process in MetaMask.
            <br />
            Once imported, your balances and community governance DAO token incentives will be displayed, enabling you to shape GetonGlobal’s future. The imported balances are final and based on official records from all merged platforms.
            <br />
            All token supplies are publicly and transparently presented live in the official
            <a
              href="https://waveswaps.com/supplies"
              style={{ color: "#0B4182", textDecoration: "underline" }}
            > Multichain Supplies</a> table.
            <br /><br />
            <b style={{ color: "#0B4182", fontSize: "1.1em" }}>Please note that no further complaints regarding the import process will be addressed, as this topic is now considered closed. For several years, the Geton team has diligently safeguarded and maintained user balances to ensure their integrity. From the moment of import, users will have total and sole control of their tokens through their own custody wallets. The Geton team affirms that there has never been, and will never be, any intention to take away a single token from anyone. This process reflects our commitment to transparency, fairness, and empowering users with full ownership of their assets.</b>
          </p>
{isConnected ?(
          <div
            className="input-group mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="email"
              className="custom-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "80%", maxWidth: "400px", marginBottom: "10px" }}
            />

            {isCodeSent ? (
              <div
                style={{ width: "80%", maxWidth: "400px", textAlign: "center" }}
              >
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter the code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  style={{ width: "100%", marginBottom: "10px" }}
                />

                <button
                  onClick={handleVerifyCode}
                  className="buton mb-1 btn btn-primary"
                  style={{ width: "100%", display: "block", margin: "0 auto" }}
                >
                  Authenticate
                </button>
              </div>
            ) : (
              <button
                className="buton mb-1 btn btn-primary"
                onClick={handleSendCode}
                style={{ width: "80%", maxWidth: "400px", margin: "20px auto" }}
              >
                Request Authentication Code
              </button>
            )}

            {error && (
              <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
            )}
          </div>
          ):(<div style={{whiteSpace:"normal"}}><ConnectButton /><br /></div>)}
        </div>
      ) : (
        <div>
          <br />
          <br />
          <h2 className="pageTitle balances-header">
            Your Imported Token Balances
          </h2>

          <p className="hero-p sub-header" style={{ textAlign: "justify" }}>
            Effortlessly manage your token holdings with the tools provided. The
            GetonGlobal dApp, built for the community, is now 100% owned and
            managed by the DAO Token holders and also by —You!
            <br />
            Your participation directly shapes the platform’s direction,
            liquidity, and value. Your token holdings are already positioned on
            a clear path toward enhanced liquidity and growth, offering tangible
            opportunities for prosperity.
            <br />
            Now, with the option to transform your imported balances to Power Cores on the Polygon network—unique NFTs with established value. These Power Cores unlock their full utility within the WaveSwaps Liquidity Nodes mechanism, offering you greater stability and opportunities for growth.
            <br />
            This public, open-source platform embodies collective empowerment.
            By actively engaging in DAO governance, you drive decisions,
            strengthen the ecosystem, and unlock its full potential for a
            thriving, shared future.{" "}
            <a
              href="https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/token-holdings"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: "10px",
                fontStyle: "italic",
                color: "#0B4182",
                fontSize: "12px",
                textDecoration: "none",
              }}
            >
              Read more ...
            </a>
          </p>

          <div className="table-responsive" style={{ overflowX: "auto" }}>
            <Table
              className="w-100 no-border-bottom"
              style={{ borderCollapse: "collapse", minWidth: "800px" }}
            >
              <thead>
                <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Logo
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Token Name
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Balance
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Ecosystem Value ($)
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Market Value ($)
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    DAO Value (DAO)
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Power Core (WAVE)*
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Accessories
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(balances).map(
                  ([token, balance]) =>
                    tokenIcons.includes(token) &&
                    balance > 0 && (
                      <tr key={token}>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          <a
                            href={SETTINGS_GETON.TOKENS[token].chainExplorer}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={`https://logos.waveswaps.com/assets/${token}.svg`}
                              alt={SETTINGS_GETON.TOKENS[token].logo}
                              width="23"
                              height="auto"
                            />
                          </a>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          <span className="asset-name">
                            {SETTINGS_GETON.TOKENS[token].name}
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          {(
                            parseFloat(balance) /
                            10 ** decimals[token]
                          ).toLocaleString()}{" "}
                          <span className="asset-symbol">
                            {SETTINGS_GETON.TOKENS[token].symbol}
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          {(
                            daoPrices[token] *
                            (parseFloat(balance) / 10 ** decimals[token])
                          ).toLocaleString()}{" "}
                          USDT <br />
                          <small>({daoPrices[token]} USDT)</small>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          {loading ? (
                            <Spinner className="loaderSmall" />
                          ) : (
                            <>
                              {(
                                assetData[token]?.price *
                                (parseFloat(balance) / 10 ** decimals[token])
                              ).toLocaleString()}{" "}
                              USDT <br />
                              <small>({assetData[token]?.price} USDT)</small>
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          {(
                            daoPrices[token] *
                            (parseFloat(balance) / 10 ** decimals[token])
                          ).toLocaleString()}{" "}
                          DAO
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >

{parseFloat(((assetData[token]?.price * (parseFloat(balance) / 10 ** decimals[token]))/30).toFixed(0)).toLocaleString()} <small>WAVE Power Core</small>
                    
                          
                    <br />
                            <small>1 WAVE Power Core / 30 USD</small>
                        </td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "13px",
                            color: "#555",
                          }}
                        >
                          <button
                            onClick={() =>
                              copyToClipboard(
                                SETTINGS_GETON.TOKENS[token].tokenAddress1
                              )
                            }
                            className="copy-button"
                          >
                            <BiCopy className="icon" />
                          </button>
                          <button
                            onClick={() =>
                              handleAddToken(SETTINGS_GETON.TOKENS[token])
                            }
                            className="add-token-button"
                            style={{ marginRight: "10px" }}
                          >
                            <img
                              src="https://tokens.waveswaps.com/images/metamask.svg"
                              alt="MetaMask"
                            />
                          </button>
                          <a
                            href="https://app.uniswap.org/swap"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginRight: "10px" }}
                          >
                            <img
                              src="https://tokens.waveswaps.com/images/dex/uni.svg"
                              alt="Uniswap"
                              width="20"
                              height="auto"
                            />
                          </a>
                          <a
                            href={SETTINGS_GETON.TOKENS[token].chart}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://tokens.waveswaps.com/images/cmc.svg"
                              alt="Chart"
                              width="20"
                              height="auto"
                            />
                          </a>
                        </td>
                      </tr>
                    )
                )}
        <tr>
      <td colSpan={8}>
        {claimed >= 0 ? (
          <div className="claim-status">
            {claimed >= 1 ? (
              <div className="status success">
                <BiCheckCircle className="icon success-icon" />
                <div className="message">
                  <strong>Transformation to  {parseFloat(coresToClaim).toLocaleString()} WAVE Power Cores Successfully executed! Visit <a href="https://waveswaps.com/list" target="_blank">Portfolio</a>.</strong>
                </div>
              </div>
            ) : (
              <div className="status processing">
                <Spinner className="icon spinner-icon" />
                <div className="message">
                  <strong>Processing...</strong> The transformation of {parseFloat(coresToClaim).toLocaleString()} WAVE Power Cores has been successfully initiated.  Execution will be completed within 48 hours. 
                  <br />Once processed, your updated WAVE Power Core balance will be visible in WaveSwaps / Nodes / Portfolio. 
                  <a
                href="https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/token-allocation"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginRight: "10px",
                  fontStyle: "italic",
                  color: "#0B4182",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
              > Read more ...
              </a>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="withdraw-component">
            <TokenWithdrawComponent
              provider={provider}
              account={address}
              email={email}
              amount={coresToClaim}
            />
          </div>
        )}
      </td>
    </tr>
              </tbody>
            </Table>
            <p style={{ textAlign: "left", fontSize: "12px"}}>
                *The Power Cores column represents the calculated value of your imported token holdings transformed into Wave Power Cores, essential for activating Liquidity Nodes on the Polygon network. These unique NFTs play a critical role in the WaveSwaps ecosystem, enabling immediate rewards in WAVE and driving liquidity growth. <a
                href="https://docs.waveswaps.com/nodes/liquidity-nodes"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginRight: "10px",
                  fontStyle: "italic",
                  color: "#0B4182",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
              >
                Learn more about the functionality and benefits of Liquidity Nodes ...
              </a>

            </p>
            <p style={{ textAlign: "left" }}>
              <a
                href="https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/dao-governance"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginRight: "10px",
                  fontStyle: "italic",
                  color: "#0B4182",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
              >
                Explore the Table in details ...
              </a>
            </p>
          </div>

          <DAOTokenComponent
            provider={provider}
            account={address}
            daoClaimAmount={daoTokensToClaim}
          />
        </div>
      )}
    </div>
    </>
  );
};

export default GetonBalances;
