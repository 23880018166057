// src/components/ponzigetonzi/ClaimTokens.js

import React, { useState } from 'react';
import { BrowserProvider, Contract } from 'ethers';
import presaleContractInfo from '../../contracts/PresaleContract.json';
import { Button, Card, Alert } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

function ClaimTokens() {
  const [statusMessage, setStatusMessage] = useState('');

  const claimTokens = async () => {
    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Initialize provider and signer
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const presaleContract = new Contract(
        presaleContractInfo.address,
        presaleContractInfo.abi,
        signer
      );

      const txResponse = await presaleContract.claimTokens();
      setStatusMessage('Transaction submitted. Waiting for confirmation...');
      await txResponse.wait();
      setStatusMessage('Tokens claimed successfully!');
    } catch (error) {
      console.error(error);
      setStatusMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <Card className="custom-card text-center">
      <Card.Header>
        <FaCheckCircle size={32} /> Claim Your Tokens
      </Card.Header>
      <Card.Body>
        <Button variant="success" className="custom-button btn-custom" onClick={claimTokens}>
          Claim Tokens
        </Button>
        {statusMessage && (
          <Alert variant="info" className="mt-3">
            {statusMessage}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
}

export default ClaimTokens;
