const SETTINGS_GETON = {
  TOKENS: {
    PIPL: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/PIPL.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc",
      name: "PiplCoin",
      symbol: "PIPL",
      walletAddress: "0xc7d018cf9f5fd54d466321cf9398420c3bd26fca",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0xc7d018cf9f5fd54d466321cf9398420c3bd26fca",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },

    GETON: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
      name: "GetonCoin",
      symbol: "GETON",
      walletAddress: "0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    PRON: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/PRON.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x2a6af8da13b36f925632a404a3161fe344d8f469",
      name: "PronCoin",
      symbol: "PRON",
      walletAddress: "0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x2a6af8da13b36f925632a404a3161fe344d8f469",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    LOS: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
      name: "LosCoin",
      symbol: "LOS",
      walletAddress: "0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    CBDX: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x7607Cb4a4538a1a90501bc919F495889b005A63b",
      name: "CryptoBoard",
      symbol: "CBDX",
      walletAddress: "0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x7607Cb4a4538a1a90501bc919F495889b005A63b",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    GROSH: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/GROSH.svg",
      chainExplorer:
        "https://polygonscan.com/token/0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220",
      name: "GroshCoin",
      symbol: "GROSH",
      walletAddress: "0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    EGROSH: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/EGROSH.svg",
      chainExplorer:
        "https://polygonscan.com/token/0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
      name: "EuroGrosh",
      symbol: "EGROSH",
      walletAddress: "0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    APOD: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/APOD.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
      name: "Airpod Coin",
      symbol: "APOD",
      walletAddress: "0x1401483F86940E3a2ED890fa75c01E3D8A099A31",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0x1401483F86940E3a2ED890fa75c01E3D8A099A31",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
    CARB: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/CARB.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
      name: "CarbCoin",
      symbol: "CARB",
      walletAddress: "0xdd5755f257dbebc82780bf66888acafbd94da77d",
      wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
      tokenAddress1: "0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
      tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      tokenDecimals1: 18, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart:
        "https://coinmarketcap.com/dexscan/polygon/0xdd5755f257dbebc82780bf66888acafbd94da77d",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
  },
  DAO: {
    DAO: {
      assetLogo: "https://tokens.waveswaps.com/images/tokens/DAO.svg",
      chainExplorer:
        "https://polygonscan.com/token/0x8053563b0678a2Cd900BAbc8756cC55e25685215",
      name: "DAOToken",
      symbol: "DAO",
      walletAddress: "0x",
      wallet: "0x",
      tokenAddress1: "0x8053563b0678a2Cd900BAbc8756cC55e25685215",
      tokenAddress2: "0x",
      tokenDecimals1: 0, // Decimals for tokenAddress1
      tokenDecimals2: 6, // Decimals for tokenAddress2
      totalSupply: 1000000,
      chart: "https://coinmarketcap.com/",
      chainId: 137, // Polygon Mainnet chain ID
      network: "Polygon Mainnet", // Name of the network
      rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
      currencySymbol: "POL", // Currency symbol
      blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
      apiKey: "A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE",
    },
  },
};

export default SETTINGS_GETON;
