import React, { useEffect, useState } from 'react';
import governanceImg from '../governance.png';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import { Row, Col, Button, Card } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import CoinSpin from '../components/CoinSpin';
import RefferalComponent from '../components/RefferalComponent';
import { useNavigate } from 'react-router-dom';
import StripeLoaderImg from '../stripe_loader.svg';
import { BiInfoCircle } from 'react-icons/bi';
import Usdt from './Usdt';
import RoadmapSection from '../components/RoadmapSection';
import GetonBalances from '../components/GetonBalances';
import FAQs from './FAQs';




function GlobalDashboard({ provider, address, isConnected, networkId, switchNetwork }) {
 
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [airdropPower, setAirdropPower] = useState('0.00');
  const [totalNodesAmount, setTotalNodesAmount] = useState('0.00');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


//Waveswaps

  const openDaoStandard = () => {
    navigate('/dao-standard');
}
  const openDaoWeighted = () => {
    navigate('/dao-weighted');
}

  const openCraft = () => {
    navigate('/craft');
  }

  const openGame = () => {
    navigate('/game');
  }
  const openBalances = () => {
    navigate('/geton-balances');
}
  const openSupplies = () => {
      navigate('/supplies');
  }
  const images = [
    'https://wave.waveswaps.com/geton/geton.svg',
    'https://wave.waveswaps.com/geton/grosh.svg',
    'https://wave.waveswaps.com/geton/pipl.svg',
    'https://wave.waveswaps.com/geton/cbdx.svg',
    'https://wave.waveswaps.com/geton/code.svg',
    'https://wave.waveswaps.com/geton/egrosh.svg',
    'https://wave.waveswaps.com/geton/meta.svg',
    'https://wave.waveswaps.com/geton/pron.svg',
    'https://wave.waveswaps.com/geton/carb.svg',
    'https://wave.waveswaps.com/geton/los.svg',
    'https://wave.waveswaps.com/geton/apod.svg',
  ];
  const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 9000);
  
      return () => clearInterval(interval);
    }, [images.length]);
  
    return (
      <div className="image-slider rounded">
        {images.map((imgSrc, index) => (
          <img
            key={index}
            src={imgSrc}
            alt={`Slider image ${index + 1}`}
            className={`slider-image ${index === currentIndex ? 'show' : ''}`}
          />
        ))}
      </div>
    );
  };

  


  async function getBalance() {
    if (!isConnected)
      setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {

      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(address);
      setTokenBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      

      const daoTokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
        const daoBalance = await daoTokenContract.balanceOf(address);
        setAirdropPower(parseFloat(daoBalance.toString()).toFixed(0));
    
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoading(false);
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }

  useEffect(() => {
    if (isConnected) {
      getBalance();

    } else {

      setTokenBalance("0.00");
 
    }
  }, [isConnected, provider]);



  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <img src={StripeLoaderImg} style={{ marginTop: "300px" }} />
        <br />

      </div>
    );
  }

  return (
    <div style={{padding:"40px"}}>

      {/* Welcome */}
      <h2 className="dashboard-header">Dashboard</h2> 
      <p className="hero-p sub-header">Master the art of price predictions and grow your success with Geton Global.<br/>Import your token balances, join the Price Prediction Game, and earn rewards by anticipating the future of WaveCoin.</p> 




      {/* Dashboard */}
      <div className="package-grid">

      <Row className="mt-1 mb-md-3 mb-0 g-5 flex-column flex-md-row">
  {/* WaveCoin */}
  <Col xs={12} sm={6} md={6} lg={4}>

    <Card className="package-card chat-message">
      <Card.Title className="mt-2 card-title">Price Prediction Game</Card.Title>
      <Card.Body className="d-flex flex-column pt-0">
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={12}>
            <center style={{ height: "96px" }}>
              <CoinSpin />
            </center>
          </Col>
        
        </Row>


            <Button variant="primary" className='buton mb-1 mt-4'
              onClick={openGame}
              
            >
              Play Game
            </Button>
     
        <hr />
        <Row>
         
          <Col xs={12} className="fw-normal small card-balance mt-2 mb-2">
            <small>Your balance:</small>
            
              <span className='card-balance'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  
  </Col>

        {/* Account */}
        <Col xs={12} sm={6} md={6} lg={4}>
    <Card className="package-card chat-message">
      <Card.Title className="mt-2 card-title">My Account</Card.Title>
      <Card.Body className="d-flex flex-column pt-0">
              <Row className='mt-4 mb-4 align-items-center'>
                <Col xs={6} md={6}>
                  <ImageSlider images={images} />
                </Col>
                  {/* Buttons: Market and Craft */}
                <Col xs={6} md={6} className='mb-1'>
                  <Button 
                    variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                    onClick={openBalances}
                    className='buton mb-4 button-buy'
                  >
                    Balances
                  </Button>
                  <Button 
                    variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                    onClick={() => window.open('https://waveswaps.com/', '_blank')} 
             
                    className='buton button-buy'
                  >
                    WaveSwaps
                  </Button>
                </Col>
              </Row> 
              <Button variant="primary" className='buton mb-1' onClick={openSupplies}>
                Multichain Supplies
              </Button>
              <hr />
              <RefferalComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
            </Card.Body>
          </Card>
        </Col>

        {/* DAO Governance */}
        <Col xs={12} sm={6} md={6} lg={4}>
    <Card className="package-card chat-message">
      <Card.Title className="mt-2 card-title">DAO Governance</Card.Title>
      <Card.Body className="d-flex flex-column pt-0">
              <Row className='mt-3 mb-3 align-items-center'>
                <Col xs={6} md={6}>
                  <center style={{ height: "96px" }}>
                  <img src={governanceImg} className='tokenIconColor floatAvatar mx-auto' style={{ width: "90px", marginBottom: "5px" }} alt={"DAO"} /> 
                  </center>
                </Col>
                <Col xs={6} md={6}>
                  <Row className='mb-1'>
                      <Col xs={12}>
                      <Button 
                        variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                        onClick={openDaoStandard} 
                        className='buton mb-3 button-buy'
                      >
                        Standard
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Col><Button variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                      onClick={openDaoWeighted} 
                      className='buton button-buy'>
                        Weighted
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
              <Col xs={12} md={12}>                 
                   <p className='mt-1 mb-3 small mt-1'>
                    Each earned <img src={governanceImg} className='tokenIconColor' style={{ width: "22px", marginRight: "5px", marginLeft: "4px", marginTop: "-2px" }} alt={"AP"} />boosts your voting power in the
                  </p>
                 <p className='d-flex align-items-center justify-content-center h5'>DAO Voting</p>

                </Col>
              </Row>
              <hr />
              <h6 className='fw-normal mb-0 pt-0 small card-balance'>
                  <small>Your balance:</small> <img src={governanceImg} className='tokenIconColor' style={{ width: "18px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <span className='card-balance'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(airdropPower)} DAO</span>
                </h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>



      {/* FAQs */}
      <div className="package-grid mb-4">

        <Row className="mt-1 mb-md-3 mb-0 g-5 flex-column flex-md-row">
          <Col xs={12} md={6} lg={4} xl={4} className="mb-1">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start'>
                <h6>**Price Prediction Game</h6>
                <p className='text-game justify'>
                This game allows users to make daily high or low bets on WaveCoin’s price trend, earning WaveCoin rewards within a controlled, interval-based model that doesn’t directly affect the asset’s market price.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} xl={4} className="mb-1">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start'>
                <h6>*Easily manage your account</h6>
                <p className='text-game justify'>
                Easily manage your account from one place. Access your token balances, initiate swaps, and share your unique referral link. With these quick tools, staying engaged and earning rewards has never been simpler.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} xl={4} className="mb-1">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start d-flex flex-column'>
                <h6>Check out FAQs</h6>
                <p className='text-game  justify'>
                  Learn more about how Geton Global works.
                </p>
                <Button variant="primary buton mt-auto d-flex align-items-center justify-content-center mb-2" onClick={openFAQs} className='buton'>
                  <BiInfoCircle className='me-1' /> OPEN FAQs
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </div>

      </div>
              {/* Import */}
      <div>
      <GetonBalances provider={provider}  address={address} isConnected={isConnected} networkId={networkId} switchNetwork={switchNetwork} />
    </div>
  
       
       
        <RoadmapSection />
        <FAQs />
        
    </div>
  );
}

export default GlobalDashboard;
