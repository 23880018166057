import React, { useState, useEffect } from "react";
import { BiCopy, BiInfoCircle } from "react-icons/bi";
import { Spinner, Button } from "react-bootstrap";
import SETTINGS_GETON from "../SETTINGS_GETON";
import { BrowserProvider, Contract, parseUnits, formatUnits } from "ethers";

const CONTRACT_ADDRESS = "0xaBfaDDA18766978Afc2b0453658071DE9404a75B";
//const CONTRACT_ADDRESS = "0xc9650CEEB0A8a8467794fd136516a2921CAeF583";
const CONTRACT_ABI = [
  "function claimDAOToken(uint256 amount) public",
  "function withdrawDAOToken() public",
  "function userClaims(address user) view returns (uint256 totalClaimed, uint256 withdrawn, uint256 startTime)",
];

const DAOTokenComponent = ({ provider, account, daoClaimAmount }) => {
  const [isClaimed, setIsClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claimAmount, setClaimAmount] = useState(0);
  const [withdrawnAmount, setWithdrawnAmount] = useState(0);
  const [nextWithdrawalTime, setNextWithdrawalTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    checkClaimStatus();
  }, []);

  useEffect(() => {
    // Start the countdown if there's a valid next withdrawal time
    if (nextWithdrawalTime) {
      const interval = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        const remaining = nextWithdrawalTime - currentTime;
        setTimeRemaining(remaining > 0 ? remaining : 0);

        // Clear the interval if the countdown is done
        if (remaining <= 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [nextWithdrawalTime]);

  const checkClaimStatus = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

      const userClaim = await contract.userClaims(account);
      const hasClaimed = parseInt(userClaim.totalClaimed.toString()) > 0;

      setIsClaimed(hasClaimed);
      setClaimAmount(parseInt(userClaim.totalClaimed.toString()));
      setWithdrawnAmount(parseInt(userClaim.withdrawn.toString()));

      if (hasClaimed) {
        const ONE_DAY_IN_SECONDS = 24 * 60 * 60;
        const lastWithdrawalTime =
          parseInt(userClaim.startTime.toString()) +
          Math.floor(
            parseInt(userClaim.withdrawn.toString()) /
              (parseInt(userClaim.totalClaimed.toString()) / 100)
          ) *
            ONE_DAY_IN_SECONDS;
        setNextWithdrawalTime(lastWithdrawalTime + ONE_DAY_IN_SECONDS);
      }
    } catch (error) {
      console.error("Error checking claim status:", error);
    }
  };

  const claimTokens = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    try {
      const amountToClaim = parseUnits(daoClaimAmount.toString(), 0);
      const tx = await contract.claimDAOToken(amountToClaim);
      await tx.wait();
      setIsClaimed(true);
      setClaimAmount(amountToClaim);
    } catch (error) {
      console.error("Error claiming tokens:", error);
    }
    setLoading(false);
  };

  const withdrawTokens = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    try {
      const tx = await contract.withdrawDAOToken();
      await tx.wait();
      checkClaimStatus();
    } catch (error) {
      if (error.error && error.error.message) {
        console.error("Error withdrawing tokens:", error.error.message);
      } else {
        console.error("Error withdrawing tokens:", error);
      }
    }
    setLoading(false);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage,
    chainId,
    networkName,
    rpcUrl,
    currencySymbol,
    blockExplorerUrl
  ) => {
    try {
      if (typeof window.ethereum === "undefined") {
        alert(
          "MetaMask is not installed. Please install MetaMask to use this feature."
        );
        return;
      }

      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x" + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x" + chainId.toString(16),
                    chainName: networkName,
                    rpcUrls: [rpcUrl],
                    nativeCurrency: {
                      name: currencySymbol,
                      symbol: currencySymbol,
                      decimals: 18,
                    },
                    blockExplorerUrls: [blockExplorerUrl],
                  },
                ],
              });
            } catch (addError) {
              console.error("Error adding network:", addError);
              return;
            }
          } else {
            console.error("Error switching network:", switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(
          `${tokenSymbol} successfully added to MetaMask on ${networkName}`
        );
      } else {
        console.log("Token addition to MetaMask failed.");
      }
    } catch (error) {
      console.error("Error adding token to MetaMask:", error);
    }
  };

  const handleAddToken = (asset) => {
    const {
      tokenAddress1,
      symbol,
      tokenDecimals,
      assetLogo,
      chainId,
      network,
      rpcUrl,
      currencySymbol,
      blockExplorerUrl,
    } = asset;

    addTokenFunction(
      tokenAddress1,
      symbol,
      tokenDecimals,
      assetLogo,
      chainId,
      network,
      rpcUrl,
      currencySymbol,
      blockExplorerUrl
    );
  };

  return (
    <>
    {loading && (
      <div className="gameLoader">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="gameSpinner">
        <Spinner  color="#00AEEF" height={240} width={240} />
        </div>
        </div>
    )}
    <div className="container" style={{ padding: "20px", textAlign: "center" }}>
      {account ? (
        <div className="row">
          <h2 className="pageTitle balances-header">
            Your DAO Tokens and Governance Power
          </h2>

          <p className="hero-p sub-header" style={{ textAlign: "justify" }}>
            You’ve earned DAO tokens based on your imported token balances and
            the ecosystem's value, with each DAO token valuated to 1 USD. Upon
            claiming, DAO tokens will be progressively released over 100 days,
            allowing daily withdrawals or full accumulation at your convenience.
            These tokens granting voting rights and a proportional stake in
            GetonGlobal's 49% governance pool (WSHARE), sourced from collected
            fees.
            <br />
            As a DAO stakeholder, you hold the power to propose and approve
            changes, ensuring decisions are fair, transparent, and
            community-driven. The 49% governance pool continues to accumulate
            fees across profit centres, creating tangible opportunities for
            collective management and growth.{" "}
            <a
              href="https://docs.waveswaps.com/ws-official/historical-evolution/getonglobal/dao-governance"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: "10px",
                fontStyle: "italic",
                color: "#0B4182",
                fontSize: "12px",
                textDecoration: "none",
              }}
            >
              Read more ...
            </a>
          </p>

          <div className="table-responsive" style={{ overflowX: "auto" }}>
            <table
              className="w-100 no-border-bottom mb-3"
              style={{ borderCollapse: "collapse", minWidth: "800px" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    DAO Tokens
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Already Withdrawn
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Withdrawal
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Accessories
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isClaimed ? (
                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      <button
                        onClick={claimTokens}
                        className="btn btn-success w-100"
                      >
                        <img
                          src="https://tokens.waveswaps.com/images/tokens/DAO.svg"
                          width="23"
                          height="auto"
                          alt="DAO"
                        />{" "}
                        Claim {parseInt(daoClaimAmount).toLocaleString()} DAO
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "13px",
                        color: "#555",
                      }}
                    >
                      <strong>Requested:</strong>
                      <img
                        src="https://tokens.waveswaps.com/images/tokens/DAO.svg"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        width="20"
                        height="auto"
                        alt="DAO"
                      />{" "}
                      {parseInt(formatUnits(claimAmount, 0)).toLocaleString()}{" "}
                      DAO
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "13px",
                        color: "#555",
                      }}
                    >
                      <strong>Withdrawn:</strong>
                      <img
                        src="https://tokens.waveswaps.com/images/tokens/DAO.svg"
                        width="20"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        height="auto"
                        alt="DAO"
                      />{" "}
                      {parseInt(
                        formatUnits(withdrawnAmount, 0)
                      ).toLocaleString()}{" "}
                      DAO
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "13px",
                        color: "#555",
                      }}
                    >
                      {timeRemaining && timeRemaining > 0 ? (
                        <p>
                          <strong>Next Allocation:</strong>{" "}
                          {formatTimeRemaining(timeRemaining)}
                        </p>
                      ) : (
                        <button
                          onClick={withdrawTokens}
                          className="btn btn-success w-100"
                        >
                          <img
                            src="https://tokens.waveswaps.com/images/tokens/DAO.svg"
                            width="23"
                            marginRight="2px"
                            height="auto"
                            alt="DAO"
                          />{" "}
                          Withdraw DAO Tokens
                        </button>
                      )}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "13px",
                        color: "#555",
                      }}
                    >
                      <button
                        onClick={() =>
                          copyToClipboard(
                            SETTINGS_GETON.DAO["DAO"].tokenAddress1
                          )
                        }
                        className="copy-button"
                      >
                        <BiCopy className="icon" />
                      </button>
                      <button
                        onClick={() =>
                          handleAddToken(SETTINGS_GETON.DAO["DAO"])
                        }
                        className="add-token-button"
                        style={{ marginRight: "10px" }}
                      >
                        <img
                          src="https://tokens.waveswaps.com/images/metamask.svg"
                          alt="MetaMask"
                        />
                      </button>
                      <a
                        href="https://polygonscan.com/token/0x8053563b0678a2Cd900BAbc8756cC55e25685215"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: "10px" }}
                      >
                        <img
                          src="https://tokens.waveswaps.com/images/chains/polygonscan.svg"
                          alt="DAOToken"
                          width="20"
                          height="auto"
                        />
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <p
              className="hero-p sub-header mt-4"
              style={{ textAlign: "justify" }}
            >
              Dive into the{" "}
              <a
                href="/game"
                style={{ color: "#0B4182", textDecoration: "none" }}
              >
                Price Prediction Game
              </a>{" "}
              or explore the advanced functionalities of{" "}
              <a
                href="https://waveswaps.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0B4182" }}
              >
                WaveSwaps
              </a>
              , a core pillar of the Geton Ecosystem's development.
              Strategically engage your tokens in additional decentralized
              opportunities—directly, transparently, and without intermediaries.
            </p>
          </div>
        </div>
      ) : (
        <p>Please connect your wallet</p>
      )}
    </div>
    </>
  );
};

export default DAOTokenComponent;
